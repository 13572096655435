import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'; 
@Pipe({
    name: 'checknull'
})
export class CheckNullPipe implements PipeTransform{
   transform(value: any): any{
        
        return value == null || value == "null" ? "" : value;
        // checking the null values 
    }

}