import { Component, OnInit, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { RouterService } from '../../../../../shared/services/router.service';
import { DataService } from '../../../../../shared/services/data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// lodash
import * as _ from 'lodash';
import { IPoitvariables } from 'src/app/shared/models/poitvariables';
import { OnboardingDetailsService } from '../../../client-onboarding/service/onboarding-details.service';
import { ToastMessageService } from 'src/app/shared/services';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import { takeWhile } from 'rxjs/operators';
import { ModulesEnum, RoleEnum } from 'src/app/shared/constants/enum';
import { POITSharedService } from '../../../poit/services/poit-shared.service';

@Component({
  selector: 'app-doormatpopupcoseccomplianceofficer',
  templateUrl: './doormatpopupcoseccomplianceofficer.component.html',
  styleUrls: ['./doormatpopupcoseccomplianceofficer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DoormatPopupCoSecComplianceOfficerComponent implements OnInit, OnDestroy {
  clientId: Number;
  issueOfSecurityRequired: any;
  formDRequired: boolean;
  poit: boolean=false;
  sdd: boolean=false;
  contraPCApproval: boolean;
  public loading: boolean;
  ecg: boolean=false;

  // @Output() selectedDialogAction = new EventEmitter<any>();

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private dataService: DataService,
    private encryptDecryptService: EncryptDecryptService,
    private onboardingService: OnboardingDetailsService,
    private toastMessageService: ToastMessageService,
    private poitSharedService:POITSharedService
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.sessionStorageService.currentAssignmentDetails.pipe(takeWhile(() => true)).subscribe((result) => {
      this.loading = false;
      if (result) {
        this.clientId = result.ClientId;
        this.getPIOTVariables();
        this.checkModules();
          }
    });
  }
  getPIOTVariables() {
    const piotVariables: IPoitvariables = {} as IPoitvariables;
    piotVariables.ClientId = this.clientId;
    this.sessionStorageService.POITVariables.subscribe((result: any) => {
      if (result) {
        this.issueOfSecurityRequired = result.IssueOfSecurityRequired;
        this.formDRequired = result.FormDRequired;
        this.contraPCApproval = result.ContraPCApprovalRequired;
      }
    });
  }

  checkModules() {
    this.sessionStorageService.companyModuleList.subscribe((result) => {
      if (result) {
        const decryptedModules: any = this.encryptDecryptService.decrypt(result);
        decryptedModules.map((item) => {
          if (item === ModulesEnum.POIT) {
            this.poit = true;
          }
          if (item === ModulesEnum.SDD) {
            this.sdd = true;
          }
          if (item === ModulesEnum.ECG) {
            this.ecg = true;            
          } 
        });

      }
    });
  }

  ngOnDestroy(): void {
  }

  callClosePopup() {
    this.dataService.closeMenuBox();
  }

  callPreClearanceList() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToPOITCO();
  }

  callContraPC() {
    this.dataService.closeMenuBox();
    this.poitSharedService.updateWaiverData({isWaiver:true});
   this.routerService.RedirectToPOITCOWaiver();
  }

  callTradeIntimationList() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToViewTradeIntimation();
  }

  callClientOnboarding() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToClientOnboarding();
  }

  callDocumentMgmt() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToDocumentMgmt();
  }

  callSDDUPSISharing() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToUPSISharing();
  }

  callTradingWindowClosure() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToTradingWindow();
  }

  callRestrictedList() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToRestrictedList();
  }

  callIssueOfSecurity() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToIssueOfSecurities();
  }

  callRuleEngine() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToRuleEngine();
  }

  callNotificationConfig() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToNotificationConfig();
  }

  callTaskTemplate() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToTaskTemplateMain();
  }
  callFormCSubmission() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToFormCSubmission();
  }
  callTradingplan() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToTradingPlan();
  }
  callTradingplanSubmission() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToTradingPlanSubmission();
  }
  callFormDSubmission() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToFormDSubmission();
  }
  callUPSISPOC() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToDigitalDatabase();
  }

  callTradingHolidayList() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToTradingHolidayList();
  }

  callPIOT() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToPOITCO();
  }
  callReportsandFormat(){
    this.dataService.closeMenuBox();
    this.routerService.RedirectToReportsandFormats();
  }
}
