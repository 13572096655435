import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastMessageService } from 'src/app/shared/services';
@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {

  constructor(private toastMessageService: ToastMessageService) { }
  @Input() activateFilter: boolean;
  @Input() lstKey: any[];
  @Output() filterText = new EventEmitter<any>();
  searchKey: any;
  searchText: string = '';
  searchStartDate: Date;
  searchEndDate: Date;

  ngOnInit() {
  }

  callClosePopup() {
    this.activateFilter = false;
  }

  emitFilteredText() {
    if (this.searchKey) {
      this.filterText.emit({
        searchText: this.searchText,
        searchKey: this.searchKey,
        searchStartDate: this.searchStartDate,
        searchEndDate: this.searchEndDate
      });
    } else {
      this.toastMessageService.showErrorMessage('Please Select Key First.');
    }
  }
}
