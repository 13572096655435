import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptDecryptService {
    constructor() { }

    readonly key = CryptoJS.enc.Utf8.parse('7061737323313233');
    readonly iv = CryptoJS.enc.Utf8.parse('7061737323313233');

    encryptPassword(msg, pass) {
      // random salt for derivation
      const keySize = 256;
      let salt = CryptoJS.lib.WordArray.random(16);
      // well known algorithm to generate key
      const key = CryptoJS.PBKDF2(pass, salt, {
        keySize: keySize / 32,
        iterations: 100
      });
      // random IV
      const iv = CryptoJS.lib.WordArray.random(128 / 8);
      // specify everything explicitly
      const encrypted = CryptoJS.AES.encrypt(msg, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      });
      // combine everything together in base64 string
      const result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
      return result;
    }

    encrypt<T>(data: T) {
        return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(data)), this.key,
            {
                keySize: 128 / 8,
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
    }

    decrypt<T>(encryptedData: any): T {
        const decrypted = CryptoJS.AES.decrypt(encryptedData, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)) as T;
    }

}
