import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { EnvServiceProvider } from './services/env.service.provider';
import { SessionValidationService } from './services/session.validation.service';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {AutoCompleteModule} from 'primeng/autocomplete';
import * as drilldown from 'highcharts/modules/drilldown.src.js';
import * as highmaps from 'highcharts/modules/map.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { PaginatorModule } from 'primeng/paginator';
import more from 'highcharts/highcharts-more.src';
import stock from 'highcharts/modules/stock.src';
import * as HighchartsMore from 'highcharts/highcharts-more.src';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge.src';
import * as HighchartsSeriesLabel from 'highcharts/modules/series-label.src';
import * as highcharts from 'highcharts';
import { TitleCasePipe } from '@angular/common';
import { DocumentFetchService } from './shared/services/document.service';

export function highchartModules() {
  return [drilldown, highmaps, exporting];
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular modules
    BrowserModule,
    // Prime ng modules
    ToastModule,

    //  Application modules
    CoreModule,
    FormsModule,
    MessagesModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    ChartModule,
    PaginatorModule,
  ],
  providers: [
    EnvServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    SessionValidationService,
    {
      provide:  HIGHCHARTS_MODULES, useFactory: highchartModules,
    },
    TitleCasePipe,
    DocumentFetchService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
