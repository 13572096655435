import { Directive, Host } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { SessionStorageService, DataService } from 'src/app/shared/services';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appDateFormat]'
})
export class PCalendarDateFormatDirective {
  dateFormat = 'dd-M-yy';
  subscription: Subscription;

  constructor(
    @Host() private pCalendar: Calendar,
    public sessionStorageService: SessionStorageService,
    private dataService: DataService
  ) {

    this.subscription = this.dataService.getDateFormat().subscribe(format => {
      pCalendar.dateFormat = format;
    });
    if (this.sessionStorageService.getDateFormat()) {
      pCalendar.dateFormat = this.sessionStorageService.getDateFormat();
    } else {
      pCalendar.dateFormat = this.dateFormat;
    }
  }
}