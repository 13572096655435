import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidatorFn, AbstractControl, ValidationErrors, FormControl, Validators } from '@angular/forms';

@Directive({
  selector: '[VersionValidator]',
  providers: [
    {
        provide: NG_VALIDATORS,
        useExisting: VersionValidatorDirective,
        multi: true
    }
]
})
export class VersionValidatorDirective {

  validator: ValidatorFn;
  ctrlName: string;
  constructor() { }
  validate(control: AbstractControl): ValidationErrors {
    const ctrl = new FormControl(control.value, Validators.pattern('^[0-9,._-]*$'));

    if (ctrl.invalid) {
      return {
        VersionValidator: {
          valid: false
        }
      }
    }
    return null;
  }

}
