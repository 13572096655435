import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable()
export class Md5HashService {
    constructor() { }

    hashString(data: string): string {
        return this.hash(data, false).toString();
    }

    hashRaw(data: string): string | Int32Array {
        return this.hash(data, true);
    }

    private hash(data: string, returnRaw: boolean): string | Int32Array {
        return Md5.hashStr(data, returnRaw);
    }
}
