import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, ValidationErrors, FormControl, Validators } from '@angular/forms';

@Directive({
  selector: '[EmailValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailvalidatorDirective,
      multi: true
    }
  ]
})
export class EmailvalidatorDirective implements Validator {
  validator: ValidatorFn;
  ctrlName: string;
  constructor() { }
  validate(control: AbstractControl): ValidationErrors {
    let contrl = new FormControl(control.value, Validators.pattern('^[A-Z0-9a-z._%]+@[A-Za-z0-9.-]+.[A-Za-z]{2,64}$'));

    if (contrl.invalid) {
      return {
        EmailValidator: {
          valid: false
        }
      }
    }
    return null;
  }
}
