import { Injectable } from '@angular/core';
import { ResponseMessages } from 'src/app/shared/constants/response-messages';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/services/http.service';
import { EnvService } from 'src/app/services/env.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkflowManagerService {

  private readonly apiprojectUrl = this.env.baseUrl + environment.services.workflow.baseUrl;
  private readonly apiClientOnboardingUrl = this.env.baseUrl + environment.services.onboarding.baseUrl;
  private readonly baseUrl = this.env.baseUrl + environment.services.notification.baseUrl;
  constructor(private httpService: HttpService, private env: EnvService) { }

  getAllWorkflows(data) {
    const url = environment.services.workflow.api.listworkflows;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  getWorkflow(data) {
    const url = environment.services.workflow.api.getWorkflow;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  addUpdateWorkflow(data) {
    const url = environment.services.workflow.api.addUpdateWorkflow;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  deleteWorkflow(data) {
    const url = environment.services.workflow.api.deleteWorkflow;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  getRolesList() {
    const url = environment.services.onboarding.api.listRoles;
    return this.httpService.post<any>(this.apiClientOnboardingUrl, url, {});
  }

  public getAllNotifications(data): Observable<any> {
    const url = environment.services.notification.api.notificationList;
    return this.httpService.post<any>(this.baseUrl, url, data);
  }

  public getWorkflowActions(data) {
    const url = environment.services.workflow.api.getWorkflowActions;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
}
