import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';

import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { WelcomeUserComponent } from './components/welcome-user/welcome-user.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';


/* primeng module */

import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PipeModule } from './../pipe/pipe.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DashboardDataLoadingComponent } from './components/dashboard-data-loading/dashboard-data-loading.component';
import { DashboardNoRecordComponent } from './components/dashboard-no-record/dashboard-no-record.component';
// import {ButtonModule} from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { DashboardFilterComponent } from './components/dashboard-filter/dashboard-filter.component';
// import { ToastMessageService } from '../shared/services/toast-message.service';
import { MessageService } from 'primeng/api';
import { AlphanumericValidatorDirective } from './directives/alphanumericvalidator.directive';
import { EmailvalidatorDirective } from './directives/emailvalidator.directive';
import { NumericValidatorDirective } from './directives/numeric-validator.directive';
import { VersionValidatorDirective } from './directives/version-validator.directive';
import { KeypressValidatorDirective } from './directives/keypress-validator.directive';
import { PCalendarDateFormatDirective } from './directives/date-format.directive';
import { NumberDirective } from './directives/numbers-only.directive';
import { InputRestrictionDirective } from './directives/input-restrictions.directive';
import { DataService } from '../shared/services/data.service';
import { WorkflowManagerService } from '../zibble/cosec/workflow-manager/service/workflow-manager.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    DropdownModule,
    TooltipModule,
    CalendarModule,
    PipeModule,
    DialogModule,
    TableModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    BreadcrumbModule,
    FileUploadModule
  ],
  declarations: [WelcomeUserComponent, SectionHeaderComponent, LoaderComponent, FileUploadComponent,
    DashboardDataLoadingComponent, DashboardNoRecordComponent, DashboardFilterComponent,
    AlphanumericValidatorDirective, EmailvalidatorDirective, NumericValidatorDirective, VersionValidatorDirective,
    KeypressValidatorDirective, PCalendarDateFormatDirective, NumberDirective, InputRestrictionDirective],
  entryComponents: [FileUploadComponent],
  exports: [WelcomeUserComponent, SectionHeaderComponent, LoaderComponent, DashboardFilterComponent,
    AlphanumericValidatorDirective, EmailvalidatorDirective, NumericValidatorDirective, VersionValidatorDirective,
    KeypressValidatorDirective, PCalendarDateFormatDirective, NumberDirective, InputRestrictionDirective],
  providers: [MessageService, ConfirmationService, WorkflowManagerService]
})
export class CommonComponentsModule { }
