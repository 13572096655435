import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-no-record',
  templateUrl: './dashboard-no-record.component.html',
  styleUrls: ['./dashboard-no-record.component.css']
})
export class DashboardNoRecordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
