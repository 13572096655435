import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/services/http.service';
import { EnvService } from 'src/app/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class CodesAndPoliciesService {

  constructor(
    private http: HttpService,
    private env: EnvService
  ) { }

  private readonly apiprojectUrl = this.env.baseUrl + environment.services.codesAndPolicies.baseUrl;

  getAllCodesPolicies(data) {
    const url = environment.services.codesAndPolicies.api.getAllCodesPolicies;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

  updateCodePolicyDetails(data) {
    const url = environment.services.codesAndPolicies.api.updateCodePolicyDetails;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

  getAllKeyConsiderationsForPolicy(data) {
    const url = environment.services.codesAndPolicies.api.getAllKeyConsiderationsForPolicy;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

}
