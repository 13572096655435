

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({providedIn:'root'})
export class POITSharedService {
    getPreclearanceObj:BehaviorSubject<any>;
    getFilterListData:BehaviorSubject<any>;
    getWaiverTemplate:BehaviorSubject<any>;
    constructor(){
        this.getPreclearanceObj=new BehaviorSubject({Id:0});
        this.getFilterListData=new BehaviorSubject({reporttype:"",columnslist:[],excludelist:[],excludeoptionshow:false,header:''});
        this.getWaiverTemplate=new BehaviorSubject({isWaiver:false});
    }

    
    updatePrecleranceObj(messagechnage) {    
    this.getPreclearanceObj.next(messagechnage);
    // console.log('this.getPreclearanceObj', this.getPreclearanceObj)
    }
    
    getPreclearanceData(){
      // console.log('this.getPreclearanceObj - fatch', this.getPreclearanceObj)
      return this.getPreclearanceObj.asObservable();
    }
    updateFilterData(filterdata){
      this.getFilterListData.next(filterdata);
  }

  getFilterData(){
   return this.getFilterListData.asObservable();
  }
  
  updateWaiverData(filterdata){
      this.getWaiverTemplate.next(filterdata);
  }
  getWaiverData(){
   return this.getWaiverTemplate.asObservable();
  }
}