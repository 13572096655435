import { Component, OnInit, Input } from '@angular/core';
import { ProgressBarService } from '../../services/progress-bar.service';
@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  progressBar = true;
  constructor(private progressBarService: ProgressBarService) { }

  ngOnInit() {
    this.progressBarService.change.subscribe(progressBar => {
      this.progressBar = progressBar;
    });
  }

}
