import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { pipe } from '@angular/core/src/render3/pipe';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
  })
export class HttpService {
	// postAnonymous: <T>(url: string, data: any) => Observable<T>;
	get1: <T>(url: string) => Observable<T>;
	get: <T>(baseUrl: string, secondayUrl: string) => Observable<T>;
	post2: <T>(url: string, secondaryUrl: string, data: any, config: any) => Observable<T>;
	post1: <T>(url: string, data: any) => Observable<T>;
	post: <T>(url: string, secondaryUrl: string, data: any) => Observable<T>;
	put1: <T>(url: string, data: any) => Observable<T>;
	put: <T>(baseUrl: string, secondaryUrl: string, data: any) => Observable<T>;
	delete1: <T>(url: string, data: any) => Observable<T>;
	delete: <T>(baseUrl: string, secondaryUrl: string) => Observable<T>;
	decrypt: <T>(data: any) => T;
	encrypt: <T>(data: T) => string | any;
	getDownload: <T>(baseUrl: string, secondayUrl: string) => Observable<T>;
	postDownload: <T>(baseUrl: string, secondayUrl: string, data: any) => Observable<T>;

	constructor(private httpClient: HttpClient, private encryptDecryptService: EncryptDecryptService) {
		const vm = this;
		// vm.postAnonymous = <T>(url: string, data: any) => {
		// 	return vm.httpClient.post<T>(url, data).pipe(map((response: T) => {
		// 		return this.decrypt(response);
		// 	}));
		// };

		vm.get1 = <T>(url: string) => {
			return vm.httpClient.get<T>(url).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};

		vm.get = <T>(baseUrl: string, secondaryUrl: string) => {
			return vm.httpClient.get<T>(baseUrl + this.encrypt<string>(secondaryUrl)).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};

		vm.post1 = <T>(url: string, data: any) => {
			return vm.httpClient.post<T>(url, data).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};

		vm.post = <T>(baseUrl: string, secondaryUrl: string, data: any) => {
			return vm.httpClient.post<T>(baseUrl + this.encrypt<string>(secondaryUrl), this.encrypt<any>(data)).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};

		vm.post2 = (baseUrl: string, secondaryUrl: string, data: any, config: any) => {
			return vm.httpClient.post(baseUrl + this.encrypt<string>(secondaryUrl), this.encrypt<any>(data), config).pipe(map((response: any) => {
				return this.decrypt(response);
			}));
		};


		vm.put1 = <T>(url: string, data: any) => {
			return vm.httpClient.put<T>(url, data).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};

		vm.put = <T>(baseUrl: string, secondayUrl: string, data: any) => {
			return vm.httpClient.put<T>(baseUrl + this.encrypt<string>(secondayUrl), this.encrypt<any>(data)).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};


		vm.delete1 = <T>(url: string) => {
			return vm.httpClient.delete<T>(url).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};

		vm.delete = <T>(baseUrl: string, secondayUrl: string) => {
			return vm.httpClient.delete<T>(baseUrl + this.encrypt<string>(secondayUrl)).pipe(map((response: T) => {
				return this.decrypt(response);
			}));
		};


		vm.decrypt = <T>(data: any) => {
			const decryptedData = environment.enforceApiDecryption ? this.encryptDecryptService.decrypt<T>(data) : data;
			return decryptedData;
		};

		vm.encrypt = <T>(data: T) => {
			if (environment.enforceApiEncryption) {
				return this.encryptDecryptService.encrypt<T>(data).toString();
			}
			return data;
		};

		vm.getDownload = <T>(baseUrl: string, secondaryUrl: string) => {
			return vm.httpClient.get<T>(baseUrl + this.encrypt<string>(secondaryUrl), { responseType: 'blob' as 'json' }).pipe(map((response: T) => {
				return response;
			}));
		};

		vm.postDownload = <T>(baseUrl: string, secondaryUrl: string, data: any) => {
			return vm.httpClient.post<T>(baseUrl + this.encrypt<string>(secondaryUrl), this.encrypt<any>(data),
				{ responseType: 'blob' as 'json' }).pipe(map((response: T) => {
					return response;
				}));
		};

	}

}
