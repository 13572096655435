import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import { LocalStorageService } from '../services/local-storage.service';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';

@Injectable({
	providedIn: 'root',
  })
export class TokenInterceptor implements HttpInterceptor {
	constructor(private sessionStorage: SessionStorageService, private localStorageService: LocalStorageService,private encryptDecryptService: EncryptDecryptService) { }
	private setBasicHeaders(headers: HttpHeaders) {
		const token = this.localStorageService.authToken; // Figure out, from where to take token
		headers.set('Authorization', `Bearer ${token}`);
		headers.append('Accept', 'application/json');
		headers.append('content-type', 'Application/Json');
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.localStorageService.authToken) {
			request = request.clone({ withCredentials: true });
		} else {
			if (request.url.substring(request.url.lastIndexOf('/') + 1) === 'UploadPhoto'
					|| request.url.substring(request.url.lastIndexOf('/') + 1) === 'upload'
					|| request.url.substring(request.url.lastIndexOf('/') + 1) === 'uploadHeaderFooterLogo'
					|| request.url.substring(request.url.lastIndexOf('/') + 1) === 'uploadHeaderFooterLogoPreview') {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${this.localStorageService.authToken}`,
						UId: this.encryptDecryptService.encrypt<string>(this.localStorageService.userDetails.Email).toString(),
						SId: this.encryptDecryptService.encrypt<string>(this.localStorageService.userDetails.SessionId).toString()
					}
				});
			} else {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${this.localStorageService.authToken}`,
						'Content-Type': 'application/json',
						UId: this.encryptDecryptService.encrypt<string>(this.localStorageService.userDetails.Email).toString(),
						SId: this.encryptDecryptService.encrypt<string>(this.localStorageService.userDetails.SessionId).toString()
					}
				});
			}
		}
		return next.handle(request);
	}

	// private getAuthToken = () => {
	//     let isAlive: boolean = true;
	//     return this.sessionStorage.userAuthDetails.takeWhile(() => isAlive).subscribe((data) => {
	//         return data.result.authToken;
	//     });
	// };
}
