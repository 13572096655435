import { Injectable, NgZone } from '@angular/core';
import { RouterService } from '../shared/services/router.service';
import { SessionStorageService } from '../shared/services/session-storage.service';
import { Router, ActivatedRoute } from '@angular/router';

// const MINUTES_UNITL_AUTO_LOGOUT = 5 // in Minutes
const MINUTES_UNITL_AUTO_LOGOUT = 15 // in Minutes
//const CHECK_INTERVALL = 60000 // in ms
const CHECK_INTERVALL = 120000 // in ms
const STORE_KEY = 'lastAction';

@Injectable()
export class SessionValidationService {
    constructor(
        private ngZone: NgZone,
        private routerService: RouterService,
        private sessionService: SessionStorageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sessionStorageService: SessionStorageService
    ) {
        this.check();
        this.initListener();
        this.initInterval();
    }
    get lastAction() {
        return parseInt(localStorage.getItem(STORE_KEY));
    }
    set lastAction(value) {
        localStorage.setItem(STORE_KEY, value.toString());
    }
    initListener() {
        this.ngZone.runOutsideAngular(() => {
            document.body.addEventListener('click', () => this.reset());
            document.body.addEventListener('mouseout', () => this.reset());
            document.body.addEventListener('keyup', () => this.reset());
            document.body.addEventListener('mousemove', () => this.reset());
        });
    }
    initInterval() {
        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.check();
            }, CHECK_INTERVALL);
        });
    }
    reset() {
        this.lastAction = Date.now();
    }
    async  check() {
        const now = Date.now();
        const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;
        if (isTimeout) {
            this.sessionStorageService.userAuthDetails.pipe().subscribe((result) => {
                if (result) {
                    this.ngZone.run(() => this.routerService.RedirectToErrorPage());
                }
            });
        } else {
        }
    }
}
