import { Component, OnInit } from '@angular/core';
import { IUser } from '../../../shared/models/user';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
//  import { AppService } from '../../../app.service';
import { takeWhile } from 'rxjs/operators';
import { RouterService } from '../../../shared/services/router.service';

import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { DataService } from 'src/app/shared/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  userDetails: IUser;
  private isSubscriptionAlive = false;
  public isLoggedIn: boolean;
  public termsAndCondition: any;
  public displayTermsAndPolicy: boolean;
  private userDetailsSubscription: Subscription;

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private dataService: DataService,
    private localStorageService: LocalStorageService) {
    this.userDetails = {} as IUser;
  }

  ngOnInit() {
    this.displayTermsAndPolicy = false;
    this.dataService.termsAndConditions.subscribe(data => {
      if (data) {
        this.termsAndCondition = data;
      }
    });
    this.isSubscriptionAlive = true;
    this.sessionStorageService.userAuthDetails.pipe(takeWhile(() => this.isSubscriptionAlive)).subscribe((result) => {
      if (result) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = true;
      }
    });
  }

  showPrivacyStatement() {
    if (this.termsAndCondition) {
      this.displayTermsAndPolicy = true;
    }
  }
}
