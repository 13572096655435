import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { SessionStorageService } from 'src/app/shared/services';
import { NotificationService } from '../../services/notification.service';
import { WorkFlowModules, RoleEnum } from 'src/app/shared/constants/enum';
import { RouterService } from 'src/app/shared/services/router.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  @Output() notificationEvent = new EventEmitter<any>();
  constructor(
    private routerService: RouterService,
    private notificationService: NotificationService,
    private sessionStorage: SessionStorageService
  ) {
    this.notificationList = [];
    this.notificationListBkp = [];
    this.newNotifications = [];
    this.seenNotifications = [];
  }

  get notificationDetails() {
    return this.notificationList;
  }

  @Input()
  set notificationDetails(data) {
    this.sessionStorage.userDetails.pipe(takeWhile(() => true)).subscribe((result) => {
      if (result) {
        this.currentUserDetails = result;
      }
    });
    const newNotifications = [];
    const seenNotifications = [];
    if (data) {
      this.notificationList = data;
      data.forEach(element => {
        element['label'] = element.BellNotificationText ? element.BellNotificationText : element.MailTemplate;
        const pattern = /[^{}]*(?=\})/g;
        if (element.label.match(pattern)) {
          element.label.match(pattern).forEach(labelFormat => {
            if (labelFormat) {
              const tempKey = Object.keys(element.MailData).find(key => key.toLowerCase() === labelFormat.toLowerCase());
              if (tempKey && tempKey in element.MailData) {
                element.label = element.label.replace('{' + labelFormat.toString() + '}', element.MailData[tempKey].toString());
              }
            }
          });
        }
        if (element.IsRead && element.IsRead.SeenBy && element.IsRead.SeenBy.length === 0
          || (element.IsRead && element.IsRead.SeenBy && !element.IsRead.SeenBy.includes(this.currentUserDetails.Email))) {
          newNotifications.push(element);
        } else {
          seenNotifications.push(element);
        }
      });
      this.notificationListBkp = JSON.parse(JSON.stringify(data));
      this.newNotifications = newNotifications;
      this.seenNotifications = seenNotifications;
    } else {
      this.notificationList = [];
      this.newNotifications = [];
      this.seenNotifications = [];
    }
  }

  public notificationList: any[];
  public notificationListBkp: any[];
  public newNotifications: any[];
  public seenNotifications: any[];
  public currentUserDetails: any;
  public RoleName: any;

  ngOnInit(): void {
    this.sessionStorage.currentAssignmentDetails.subscribe((item: any) => {
      if (item) {
        this.RoleName = item.RoleName;
      }
    });
  }

  filterNotification(value) {
    if (value) {
      const filterList = [];
      this.notificationListBkp.forEach(ele => {
        if (ele.label.toLowerCase().includes(value.toLowerCase())) {
          filterList.push(ele);
        }
      });
      this.notificationList = filterList;
    } else {
      this.notificationList = this.notificationListBkp;
    }

    if (this.notificationList.length) {
      const newNotifications = [];
      const seenNotifications = [];
      this.notificationList.forEach(element => {
        if (element.IsRead && element.IsRead.SeenBy && element.IsRead.SeenBy.length === 0
          || (element.IsRead && element.IsRead.SeenBy && !element.IsRead.SeenBy.includes(this.currentUserDetails.Email))) {
          newNotifications.push(element);
        } else {
          seenNotifications.push(element);
        }
      });
      this.newNotifications = newNotifications;
      this.seenNotifications = seenNotifications;
    } else {
      this.newNotifications = [];
      this.seenNotifications = [];
    }
  }

  public redirectToModules(moduleName: any): any {
    if (moduleName === WorkFlowModules.TRADE_INTIMATION) {
      if (this.RoleName ===  RoleEnum.DesignatedPerson || this.RoleName === RoleEnum.INSIDER) {
          this.routerService.RedirectToTradeIntimation();
      } else {
          this.routerService.RedirectToViewTradeIntimation();
      }
    }
    if (moduleName === WorkFlowModules.PRECLEARANCE) {
      if (this.RoleName ===  RoleEnum.DesignatedPerson || this.RoleName === RoleEnum.INSIDER) {
          this.routerService.RedirectToPOIT();
      } else {
          this.routerService.RedirectToPOITCO();
      }
    }
    if (moduleName === WorkFlowModules.SDD_UPSI_SHARING) {
      if(this.RoleName != RoleEnum.DesignatedPerson && this.RoleName != RoleEnum.INSIDER)
         this.routerService.RedirectToUPSISharing();
    }
    if (moduleName === WorkFlowModules.TRADING_WINDOW_CLOSURE) {
      if(this.RoleName != RoleEnum.DesignatedPerson && this.RoleName != RoleEnum.INSIDER)
         this.routerService.RedirectToTradingWindow();
    }
    if (moduleName === WorkFlowModules.PERSONAL_DETAILS) {
      if (this.RoleName === RoleEnum.ComplianceOfficer ||this.RoleName === RoleEnum.SpecialComplianceOfficer) {
          //this.routerService.RedirectToFormCSubmission();
      }
      if (this.RoleName === RoleEnum.DesignatedPerson || this.RoleName === RoleEnum.INSIDER) {
          this.routerService.RedirectToPersonalDetails();
      }
    }
    if (moduleName === WorkFlowModules.RESTRICTED_LIST) {
      if(this.RoleName != RoleEnum.DesignatedPerson && this.RoleName != RoleEnum.INSIDER)
         this.routerService.RedirectToRestrictedList();
    }
    if (moduleName === WorkFlowModules.ISSUE_OF_SECURITY) {
      if (this.RoleName === RoleEnum.DesignatedPerson || this.RoleName === RoleEnum.INSIDER) {
          this.routerService.RedirectToPersonalDetails();
      }else{
          this.routerService.RedirectToIssueOfSecurities();
      }
    }
    if (moduleName === WorkFlowModules.TRADE_PLAN) {
      this.routerService.RedirectToTradingPlan();
    }
    if (moduleName === 'Dashboard') {
      this.routerService.RedirectToCoSecAdminDashboard();
    }
    if( moduleName === WorkFlowModules.MCAOnboarding) {
      this.routerService.RedirectToClientOnboarding();
    }

  }

  callClosePopup() {
    this.notificationEvent.emit(false);
  }

  updateNotificationStatus() {
    if (this.newNotifications.length) {
      this.newNotifications.forEach(ele => {
        ele.MailTo = JSON.stringify(ele.MailTo);
        ele.IsRead.SeenBy = JSON.stringify(ele.IsRead.SeenBy);
        ele.IsRead = JSON.stringify(ele.IsRead);
        ele.MailData = JSON.stringify({});
      });
      const sendData = JSON.parse(JSON.stringify(this.newNotifications));
      sendData.forEach(ele => {
        delete ele.label;
      });
      this.callClosePopup();
      this.notificationService.updateNotificationDetails(sendData).subscribe();
    } else {
      this.callClosePopup();
    }
  }
}
