import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../../../shared/services/router.service';
import { LoginService } from '../../services/login.service';
import { SessionStorageService } from '../../../shared/services/session-storage.service';


@Component({
	selector: 'app-logging-in',
	templateUrl: './logging-in.component.html',
	styleUrls: ['./logging-in.component.scss'],
	encapsulation : ViewEncapsulation.None

})
export class LoggingInComponent implements OnInit, OnDestroy {
	constructor(private route: ActivatedRoute
		, private routerService: RouterService
		, private loginService: LoginService
		, private sessionStorage: SessionStorageService) { }

	ngOnInit() {
		this.sessionStorage.flushOnLogout();
	}

	ngOnDestroy() {
	}
}
