import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { RouterService } from './router.service';
import { LocalStorageService } from './local-storage.service';
// import { ErrorHandlerService } from './error-handler.service';
import { SessionStorageService } from './session-storage.service';



@Injectable({
	providedIn: 'root',
  })
export class AuthGuardService implements CanActivate {

	constructor(private routerService: RouterService,
		private localStorageService: LocalStorageService,
		// private errorHandlerService: ErrorHandlerService,
		private sessionStorageService: SessionStorageService
	) {
	}

	canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
		const token = this.localStorageService.authToken;
		const userDetails = this.localStorageService.userDetails;

		this.sessionStorageService.setScreenType('');

		// Get data to authorize role
		const currentRouteRole = route.data['role'] as string;



		if (!token) {
			this.sessionStorageService.flushOnLogout();
			this.routerService.RedirectLogin();
			return false;
		} else if (currentRouteRole !== undefined && currentRouteRole !== null && currentRouteRole !== ''
			&& userDetails !== null && userDetails !== undefined) {
		} else {
			return true;
			// }


		}
	}
}
