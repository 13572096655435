import { Component, OnInit } from '@angular/core';
import { IUser } from '../../../shared/models/user';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { takeWhile } from 'rxjs/operators';
import { RouterService } from '../../../shared/services/router.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../../shared/services/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // userDetails: IUser;
  private isSubscriptionAlive = false;
  public isLoggedIn: boolean;
  // public firstname: string;
  private userDetailsSubscription: Subscription;

  constructor(private sessionStorageService: SessionStorageService, private routerService: RouterService,
    private localStorageService: LocalStorageService) {
    // this.userDetails = {} as IUser;
  }

  ngOnInit() {
    this.isSubscriptionAlive = true;
    this.sessionStorageService.userAuthDetails.pipe(takeWhile(() => this.isSubscriptionAlive)).subscribe((result) => {
      if (result) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
        // this.routerService.RedirectLogin();
      }
    });

    // this.userDetailsSubscription = this.sessionStorageService.userDetails.subscribe((result) => {
    //   if (result) {
    //     this.firstname = result.FirstName;
    //   } else {
    //     this.firstname = '';
    //   }
    // });
  }
}
