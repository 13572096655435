import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/services/env.service';
import { IDocumentUpload } from 'src/app/shared/models/document-upload';
import { IRole } from 'src/app/shared/models/role';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {

  private readonly apiSecurityBaseUrl =  this.env.baseUrl + environment.services.cosecdocument.baseUrl;
  private readonly apiSecurityBaseUrl1 =   this.env.baseUrl + environment.services.meetingDetails.baseUrl; 
  baseUrl: string;
  securityBaseUrl: string;
  constructor(private httpService: HttpService, private env: EnvService) {
    
  }
  public getAllDocuments(clientId:Number) {
    let url = environment.services.cosecdocument.api.getAllDocuments;
    // url=url+'/'+clientId;
    // return this.httpService.get<any>(this.apiSecurityBaseUrl, url);
    return this.httpService.post<any>(this.apiSecurityBaseUrl, url, clientId);
    // return this.httpService.post(this.baseUrl, url, {});

  }

  public saveDocument(viewModel:IDocumentUpload) {
    const url = environment.services.cosecdocument.api.saveDocument;
    return this.httpService.post(this.apiSecurityBaseUrl, url, viewModel);
  }
  public editDocument(Id:Number):Observable<IDocumentUpload> {
    let url = environment.services.cosecdocument.api.editDocument;
    //url=url+'/'+Id;
    return this.httpService.post(this.apiSecurityBaseUrl, url,Id)
      
  }

  public getVersionListById(filename:any):Observable<IDocumentUpload> {
    let url = environment.services.cosecdocument.api.getVersionListById;
    url=url+'/'+filename;
    return this.httpService.get(this.apiSecurityBaseUrl, url)

  }
  public downloadFileById(docid: string, filename: string): Observable<any> {
    let url = environment.services.cosecdocument.api.downloadFile;
    url = url + "/" + docid;
    return this.httpService.postDownload<any>(this.apiSecurityBaseUrl, url,filename)
  }
  public downloadFile(filename: string,docCategory:string,docType:string): Observable<any> {
    
    let url = environment.services.cosecdocument.api.downloadFile;
    url = url + "/" + filename+"/"+docCategory+"/"+docType;
    return this.httpService.postDownload<any>(this.apiSecurityBaseUrl, url,filename);
      // .pipe( 
      //   catchError(this.handleError))
  }
  public updateDocument(viewModel: IDocumentUpload) {
    const url = environment.services.cosecdocument.api.updateDocument;
    return this.httpService.post(this.apiSecurityBaseUrl, url, viewModel);
  }
  public deleteDocument(documentname: any) {
    let url = environment.services.cosecdocument.api.deleteDocument;
    url = url + "/" + documentname;
    return this.httpService.post(this.apiSecurityBaseUrl, url, documentname);
  }
   public listroles() {
    const url = "role/listroles";
    return this.httpService.post(this.apiSecurityBaseUrl1, url, {});
  }
  public downloadFileId(docId:any): Observable<any> {    
    let url = environment.services.cosecdocument.api.downloadFile;
    url = url + "/" + docId;
    return this.httpService.postDownload<any>(this.apiSecurityBaseUrl, url,'');
      // .pipe( 
      //   catchError(this.handleError))
  }
  // public getRoles(viewModel:IRole) {
  //   let url = environment.services.Orchestration.role.listroles;
    
  //   return this.httpService.post(this.apiSecurityBaseUrl, url, viewModel);
  // }
 public readTemplateDocument(documentname:string):Observable<any> {
    let url = environment.services.cosecdocument.api.readTemplateDocument;
    url = url + "/" + documentname;
    return this.httpService.get(this.apiSecurityBaseUrl, url);
  }
  
  public generateZip(dpIds: any): any {
    const url = environment.services.cosecdocument.api.generateZip;
    return this.httpService.post(this.apiSecurityBaseUrl, url, dpIds);
  }
}
