import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemovePipe } from './pipes/unique.pipe';
import { CheckNullPipe } from './pipes/checknull.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
//import { TitleCasePipePipe } from './pipes/title-case-pipe.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [RemovePipe, CheckNullPipe, ShortNumberPipe],
  declarations: [RemovePipe, CheckNullPipe, ShortNumberPipe]
})
export class PipeModule { }
