import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
// import { takeWhile } from 'rxjs/operators';
import { RouterService } from '../../../shared/services/router.service';

import { LocalStorageService } from '../../../shared/services/local-storage.service';

@Component({
  selector: 'app-login-status',
  templateUrl: './login-status.component.html',
  styleUrls: ['./login-status.component.css']
})
export class LoginStatusComponent implements OnInit, OnDestroy {

  private isSubscriptionAlive = false;
  public isLoggedIn: boolean;
  public userCellNumber: string;
  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.isSubscriptionAlive = true;
  }

  ngOnDestroy() {
    this.isSubscriptionAlive = false;
  }

  public login(): void {
  }

  public logout(): void {

    this.sessionStorageService.flushOnLogout();
  }
}
