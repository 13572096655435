import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root',
  })
export class ToastMessageService {
    constructor(private messageService: MessageService) {
    }

    showSuccessMessage(message: string) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: message });
    }

    showErrorMessage(message: string) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
    }

    showInfoMessage(message: string) {
        this.messageService.add({ severity: 'info', summary: 'Information', detail: message });
    }

    showWarningMessage(message: string) {
        this.messageService.add({ severity: 'warn', summary: 'Warning', detail: message });
    }

    showConfirm(message: string) {
        this.messageService.add({ sticky: true, severity: 'warn', summary: 'Are you sure?', detail: message });
    }
}
