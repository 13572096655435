import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  transform(num: number, args?: any): any {
    if (isNaN(num)) { return 0; } // will only work value is a number
    if (num === null) { return 0; }
    if (num === 0) { return 0; }
    let abs = Math.abs(num);
    const rounder = Math.pow(100, 1);
    const isNegative = num < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 },
      { key: '', value: 100 }
    ];

    for (let i = 0; i < powers.length; i++) {
      if (powers[i].value === 100) {
        abs = Math.round(abs * 100) / 100;
        key = powers[i].key;
        break;
      }
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    return (isNegative ? '-' : '') + abs + key;
  }

}
