import { Injectable } from "@angular/core";
@Injectable()
export class ResponseMessages {
	static success = 'Success';
	static userNameAndPasswordAreRequired = 'UserNameAndPasswordAreRequired';
	static userDoesNotExists = 'UserDoesNotExists';
	static invalidUserNameOrPassword = 'InvalidUserNameOrPassword';
	static roleNotAssignedToUser = 'RoleNotAssignedToUser';
	static usernameOrEmailRegistered = 'UsernameOrEmailRegistered';
	static inputDataNotFound = 'InputDataNotFound';
	static orderNotGenerated = 'OrderNotGenerated';
	static invalidUser = 'InvalidUser';
	static contractEnd = 'ContractEnd';
	static assignmentsMissing = 'AssignmentsMissing';
	static concurrentSessionFound='ConcurrentSessionFound';
}
