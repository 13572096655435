import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from '../../../../node_modules/rxjs';
// import { Subject } from '../../../../node_modules/rxjs';
// import { injectElementRef } from '@angular/core/src/render3';
import { SessionStorageService } from './session-storage.service';
// import { RoleEnum } from 'src/app/shared/constants/enum';
import * as _ from 'lodash';
import { TitleCasePipe } from '@angular/common';

@Injectable()
export class DataService {

  private urlSource = new BehaviorSubject('/');
  currentUrl = this.urlSource.asObservable();

  private trackerSource = new BehaviorSubject('none');
  trackerData = this.trackerSource.asObservable();
  private goldenMasterKeyValueTree = new BehaviorSubject([]);
  private activeKeyValue = new BehaviorSubject([]);
  private editKeyValue = new BehaviorSubject([]);
  currentMessage = this.goldenMasterKeyValueTree.asObservable();

  private messageSource = new BehaviorSubject('Dashboard');
  currentNavMessage = this.messageSource.asObservable();

  private loaderSource = new BehaviorSubject('actionable');
  showloader = this.loaderSource.asObservable();

  private getList = new BehaviorSubject('');
  fetchList = this.getList.asObservable();

  private currentStatus = new BehaviorSubject('');
  populatecurrentStatus = this.currentStatus.asObservable();

  private menuClick = new Subject();
  menuClicked = this.menuClick.asObservable();

  private workflowDetailsDataSource = new BehaviorSubject(undefined);
  workflowDetailsData = this.workflowDetailsDataSource.asObservable();

  private clientOnboardingDataSource = new BehaviorSubject(undefined);
  clientOnboardingData = this.clientOnboardingDataSource.asObservable();

  private ruleExecuteDetailsDataSource = new BehaviorSubject(undefined);
  ruleExecuteDetailsData = this.ruleExecuteDetailsDataSource.asObservable();

  private ruleEngineDetailsDataSource = new BehaviorSubject(undefined);
  ruleEngineDetailsData = this.ruleEngineDetailsDataSource.asObservable();

  private clientOnboardingTabChangeSource = new BehaviorSubject(undefined);
  clientOnboardingTabChange = this.clientOnboardingTabChangeSource.asObservable();

  private kmpTabChangeSource = new BehaviorSubject(undefined);
  kmpTabChange = this.kmpTabChangeSource.asObservable();

  private termsAndConditionsSource = new BehaviorSubject(undefined);
  termsAndConditions = this.termsAndConditionsSource.asObservable();

  private activateUploadDownloadOnActionBarSource = new BehaviorSubject(false);
  activateUploadDownloadOnActionBar = this.activateUploadDownloadOnActionBarSource.asObservable();

  private signatoryDeatilsDataSource = new BehaviorSubject(undefined);
  signatoryDetailsData = this.signatoryDeatilsDataSource.asObservable();

  private designatedPersonDataSource = new BehaviorSubject(undefined);
  designatedPersonData = this.designatedPersonDataSource.asObservable();

  private switchRoleSource = new BehaviorSubject(undefined);
  switchRole = this.switchRoleSource.asObservable();

  private insiderDataSource = new BehaviorSubject(undefined);
  insiderData = this.insiderDataSource.asObservable();

  private tabActiveDataSource = new BehaviorSubject(0);
  activeTabData= this.tabActiveDataSource.asObservable();

  private cinValidationSource = new BehaviorSubject(undefined);
  cinValidation = this.cinValidationSource.asObservable();

  private selectedModulesSource = new BehaviorSubject(undefined);
  selectedModules = this.selectedModulesSource.asObservable();

  private cinDataSaveSource = new BehaviorSubject(undefined);
  cinDataSave = this.cinDataSaveSource.asObservable();

  private cinDataSource = new BehaviorSubject(undefined);
  cinData = this.cinDataSource.asObservable();


  private currentCINSource = new BehaviorSubject(undefined);
  currentCIN = this.currentCINSource.asObservable();

  // private currentCompanyIdSource = new BehaviorSubject(undefined);
  // currentCompanyId = this.currentCompanyIdSource.asObservable();

  private tradingWindowSource = new BehaviorSubject(undefined);
  tradingWindowData = this.tradingWindowSource.asObservable();

  private issueOfSecuritySource = new BehaviorSubject(undefined);
  issueOfSecurityData = this.issueOfSecuritySource.asObservable();

  private allTradingPlanSource = new BehaviorSubject(undefined);
  allTradingPlanData = this.allTradingPlanSource.asObservable();

  private tradingPlanSource = new BehaviorSubject(undefined);
  tradingPlanData = this.tradingPlanSource.asObservable();

  private tradePlanViewOnlySource = new BehaviorSubject(undefined);
  tradePlanViewOnlyData = this.tradePlanViewOnlySource.asObservable();

  private disabledTadingPlan = new BehaviorSubject(undefined);
  isDisabledTradePlan = this.disabledTadingPlan.asObservable();

  private disabledOnEdit = new BehaviorSubject(undefined);
  isDisabledOnEdit = this.disabledOnEdit.asObservable();

  private dateFormat = new Subject<any>();
  private notificationData = new BehaviorSubject({});
  notificationCurrentData = this.notificationData.asObservable();

  private updatedUserScope = new BehaviorSubject(undefined);
  updatedUserScopeDetails = this.updatedUserScope.asObservable();

  private updateUPSIDetail = new BehaviorSubject({});
  updateUPSIDetailsEdit = this.updateUPSIDetail.asObservable();

  private isupsiEditable = new BehaviorSubject({});
  isUpsiEditableData = this.isupsiEditable.asObservable();

  private isTWCEditable = new BehaviorSubject({});
  isTWCEditableData = this.isTWCEditable.asObservable();

  authorData: any[];
  userId: Number;
  uniqFiltered: any[] = [];

  constructor(private sessionStorage: SessionStorageService,
    private titleCasePipe: TitleCasePipe) { }

  /*Array logics*/

  arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => this.objectsEqual(o, a2[idx]))

  objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && o1 !== null && o2 !== null && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length
      && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
      : o1 === o2


  addCommaToNumber(n) {
    while (n.toString().indexOf(',') !== -1) {
      n = n.replace(',', '');
    }
    const parts = n.toString().split('.');
    return parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, ',') + (parts[1] ? '' + parts[1] : '');
  }


  filterCurrency(masterlist: any) {
    const filtered: any[] = [];
    for (let i = 0; i < masterlist.length; i++) {
      const searchResult = masterlist[i];
      if (searchResult.GoldenKey.toLowerCase() === 'currency') {
        // let setsearchResult =   searchResult.GoldenValue;
        filtered.push({ label: searchResult.GoldenValue, value: searchResult.GoldenValue });
      }
    }
    return filtered;
  }

  public filterUsersEmailJson(typeJson: any) {
    const parseJson = JSON.parse(typeJson);
    const getKeys = Object.keys(parseJson);
    // let listArray = [];
    let returnString = '<ul class="double-line-sec">';
    getKeys.map(function (key) {
      const jasonvalue = parseJson[key];
      jasonvalue.map(function (values) {
        returnString = returnString + '<li>' + values + '</li>';
      });

    });
    returnString = returnString + '</ul>';
    return returnString;
  }

  public filterUsersEmailJson2(typeJson: any) {
    const parseJson = typeJson;
    const getKeys = Object.keys(parseJson);
    // let listArray = [];
    let returnString = '<ul class="double-line-sec">';
    getKeys.map(function (key) {
      const jasonvalue = parseJson[key];
      jasonvalue.map(function (values: any) {
        returnString = returnString + '<li>' + values + '</li>';
      });

    });
    returnString = returnString + '</ul>';
    return returnString;
  }

  public filterUsersEmailDisplay(typeJson: any) {
    const parseJson = JSON.parse(typeJson);
    const getKeys = Object.keys(parseJson);
    const listArray = [];
    getKeys.map(function (key) {
      const jasonvalue = parseJson[key];
      jasonvalue.map(function (values: any) {
        listArray.push(values);
      });

    });
    return listArray;
  }
  updateNav(data: string) {
    this.messageSource.next(data);
  }

  updateCINDataSave(data) {
    this.cinDataSaveSource.next(data);
  }
  updateCINData(data) {
    this.cinDataSource.next(data);
  }

  updateCINValidation(data: boolean, cin: any) {
    this.cinValidationSource.next(data);
    this.currentCINSource.next(cin);
  }

  updateTradingWindow(data: any) {
    this.tradingWindowSource.next(data);
  }

  updateIssueOfSecurity(data: any) {
    this.issueOfSecuritySource.next(data);
  }

  updateTradingPlanData(allData: any, data: any, bool: any, edit: any) {
    this.allTradingPlanSource.next(allData);
    this.tradingPlanSource.next(data);
    this.disabledTadingPlan.next(bool);
    this.disabledOnEdit.next(edit);
  }

  updateTWCDisable(bool: any) {
    this.isTWCEditable.next(bool);
  }

  updateCOTradingPlanData(isDisabled){
    this.tradePlanViewOnlySource.next(isDisabled);
  }

  updateSelectedModules(id: any) {
    this.selectedModulesSource.next(id);
  }

  // updateCompanyId(id: any) {
  //   this.currentCompanyIdSource.next(id);
  // }

  updateWorkflowDetailsData(data: any) {
    this.workflowDetailsDataSource.next(data);
  }

  updateRuleExecuteDetailsData(data: any) {
    this.ruleExecuteDetailsDataSource.next(data);
  }

  updateRuleEngineDetailsData(data: any) {
    this.ruleEngineDetailsDataSource.next(data);
  }

  updateClientOnboardingTab(data: any) {
    this.clientOnboardingTabChangeSource.next(data);
  }
  updateKMPTab(data: any) {
    this.kmpTabChangeSource.next(data);
  }

  updateTermsAndConditions(data: any) {
    this.termsAndConditionsSource.next(data);
  }

  updateVisibilityOfUploadDownloadOnActionBar(status: boolean) {
    this.activateUploadDownloadOnActionBarSource.next(status);
  }

  updateSignatoryDetailsData(data: any) {
    this.signatoryDeatilsDataSource.next(data);
  }
  updateDesignatedPersonData(data: any) {
    this.designatedPersonDataSource.next(data);
  }
  updateInsiderData(data: any) {
    this.insiderDataSource.next(data);
  }
  updateTabAcriveIndex(data:any){
    this.tabActiveDataSource.next(data);
  }
  startloader(data: string) {
    this.loaderSource.next(data);
  }

  openSwitchRole(data: any) {
    this.switchRoleSource.next(data);
  }

  updateClientOnboardingData(data: any) {
    this.clientOnboardingDataSource.next(data);
  }

  changeUrl(url: string) {
    this.urlSource.next(url);
  }

  closeMenuBox() {
    this.menuClick.next(true);
  }
  changedDateFormat(format: string) {
    this.dateFormat.next(format);
  }

  getDateFormat(): Observable<any> {
    return this.dateFormat.asObservable();
  }
  setNotificationData(data) {
    this.notificationData.next(data);
  }

  public checkCondition(operand, operator, val) {
    switch (operator) {
      case 'equals':
        return (operand === val);
        break;
      case 'not equals':
        return (operand !== val);
        break;
      case 'greater than or equal to':
        return (operand >= val);
        break;
      case 'less than or equal to':
        return (operand <= val);
        break;
      default:
        return false;
    }
  }

  public runOpsOnField(fieldVal, ops) {
    if (!ops || ops.length === 0) {
      return fieldVal;
    }
    let operatedField = fieldVal;
    for (let i = 0; i < ops.length; i++) {
      if (ops[i]['opName'] === 'add') {
        operatedField = operatedField + ops[i]['opValue'];
      } else if (ops[i]['opName'] === 'subtract') {
        operatedField = operatedField - ops[i]['opValue'];
      } else if (ops[i]['opName'] === 'multiply') {
        operatedField = operatedField * ops[i]['opValue'];
      } else if (ops[i]['opName'] === 'divide') {
        operatedField = operatedField / ops[i]['opValue'];
      }
    }
    return operatedField;
  }

  public filterGrid(list, criteria, filterMapping) {
    if (!list) {
      return [];
    }
    const filteredList = list.filter((elem) => {
      return this.executeRule(criteria, filterMapping, elem);
    });
    return filteredList;
  }

  public aggregate(aggrOp, list, field) {
    if (aggrOp === 'count') {
      return list.length;
    } else if (aggrOp === 'avg') {
      if (!list || list.length === 0) {
        return 0;
      }
      let sum = 0;
      list.forEach(lstItm => {
        sum += lstItm[field];
      });
      return sum / list.length;
    } else if (aggrOp === 'sum') {
      if (!list || list.length === 0) {
        return 0;
      }
      let sum = 0;
      list.forEach(lstItm => {
        sum += lstItm[field];
      });
      return sum;
    }
  }

  public getFieldValue(rule, mapping, ruleSubject) {
    let currentMapping = mapping;
    let currentOperand = ruleSubject;
    if (rule['FormName']) {
      currentMapping = currentMapping['formNames'][rule['FormName']];
      currentOperand = currentOperand[currentMapping['name']];
      if (rule['TabName']) {
        currentMapping = currentMapping['tabNames'][rule['TabName']];
        currentOperand = currentOperand[currentMapping['name']];

      }
      if (rule['FieldType'] === 'grid') {
        currentMapping = currentMapping['fields'][rule.Field];
        currentOperand = currentOperand[currentMapping['name']];
        if (rule.filterCondition) {
          currentOperand = this.filterGrid(currentOperand, rule.filterCondition, currentMapping['gridFields']);
        }
        if (rule.aggregateOperation) {
          const aggrField = currentMapping['gridFields'][rule.aggrField];
          currentOperand = this.aggregate(rule.aggregateOperation, currentOperand, aggrField);
          return currentOperand;
        }
      } else {
        return currentOperand[currentMapping['fields'][rule.Field]];
      }
    } else {
      return currentOperand[currentMapping[rule['Field']]];
    }

  }

  public executeRule(rule, mapping, ruleSubject) {
    const ruleKeys = Object.keys(rule);

    if (ruleKeys[0] === 'AND' || ruleKeys[0] === 'OR') {
      let res = false;
      const conditions = rule[ruleKeys[0]];
      for (let i = 0; i < conditions.length; i++) {
        const ruleExecRes = this.executeRule(conditions[i], mapping, ruleSubject);
        if (i === 0) {
          res = ruleExecRes;
        } else {
          if (ruleKeys[0] === 'AND') {
            res = (res && ruleExecRes);
          } else if (ruleKeys[0] === 'OR') {
            res = (res || ruleExecRes);
          }
        }
      }
      return res;
    } else {
      let operand1 = this.getFieldValue(rule, mapping, ruleSubject);
      let operand2 = rule.Value;
      if (rule.FieldOps) {
        operand1 = this.runOpsOnField(operand1, rule.FieldOps);
      }
      if (rule.ValueType === 'field') {
        operand2 = this.getFieldValue(rule.Value, mapping, ruleSubject);
      }
      return this.checkCondition(operand1, rule.Operator, operand2);
    }
  }

  updateUserScope(data) {
    this.updatedUserScope.next(data);
  }

  updateUPSIDetails(data,bool) {
    this.updateUPSIDetail.next(data);
    this.isupsiEditable.next(bool);
  }

  // transform(value: string): string {
  //   value = value.toLowerCase();
  //   const first = value.substr(0, 1).toUpperCase();
  //   return first + value.substr(1);
  // }

  titleCase(str) {
    typeof "str";
    if (str && str.trim().length) {
      return str ? this.titleCasePipe.transform(str) : '';
    }
  }

  public sumBy(array, iteratee) {
    let result:number = 0;  
    for(let i=0;i<array.length;i++) {
      var current = array[i] ? array[i][iteratee] : null;
      if (current !== undefined && current !== null) {
        result = Number(result) + Number(current) ;
      }
    }
    return result;
  }

}
