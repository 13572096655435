// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableWindowsAuthentication: false,
  enforceApiEncryption: false,
  enforceApiDecryption: false,
  StockExchange: true,
  instance: true,
  pageSize: 850,
  maxFileSize: 10485760,
  cryptoEncrytionKey: 'JuTRNow3rZ8evYPgHPEjkQ==',
  AccepectanceFiles: [".pdf", ".xls", ".xlsx", ".doc", ".docx", ".zip", ".ppt", ".pptx", ".jpeg", ".jpg", ".png", ".gif"],
  services: {
    gatewayUrl: '',
    securityURL: '',
    Orchestration: {
      baseUrl: 'Orchestration/api/',
      client: {
        ping: 'client/ping',
        listClients: 'Orchestration/clients',
        downloadPDFFromTemplate: 'Orchestration/downloadPDFfromHtmlTemplate',
        savePreclearance: 'Orchestration/savetradedetails',
        deletetradedetails:'Orchestration/deletetradedetails',
        updateClient: 'client/updateclient',
        uploadHeaderFooterLogo: 'orchestration/uploadHeaderFooterLogo',
        uploadHeaderFooterLogoPreview: 'orchestration/uploadHeaderFooterLogoPreview',
        submit: 'orchestration/updatemoduleroleuseragainstworkflow',
        sendReminder: 'orchestration/sendReminder',
        addinsiderandsendemail: 'Orchestration/addinsiderandsendemail',
        validateCIN: 'orchestration/validateCIN',
        notifyDPInsiderAboutTWC: 'orchestration/notifyDPInsiderAboutTWC',
        createStockExchangeNotifDoc: 'orchestration/createStockExchangeNotifDoc',
        downloadPdf: 'orchestration/downloadPdf',
        waiverHTMLTemplate: 'orchestration/waiverHTMLTemplate',
        createStockExchangeNotif: 'orchestration/createStockExchangeNotif',
      },
      audittrail: {
        getAuditTrail: 'audittrail/getallauditdata'
      },
      Orchestration: {

        getAuthByEmailVer2: 'Orchestration/email',
        getAuthByEmail: 'Orchestration/updateResetCode',
        getDashboard: 'Orchestration/getDashboard',
        getReportData: 'Orchestration/getReportData',
        exportPreclearanceReport: 'Orchestration/exportPreclearanceReport',
        exportUPSIandTradingReport: 'Orchestration/exportUPSIandTradingReport',
        getCurrentStockMarketRates: 'Orchestration/getCurrentStockMarketRates',
        generateFormB: 'orchestration/generateFormB',
        generateFormC: 'orchestration/generateFormC',
        generateFormD: 'orchestration/generateFormD',
        generateTradingPlan: 'orchestration/generateTradingPlan',
        generateFormCCoveringLetter: 'orchestration/generateFormCCoveringLetter',
        //benposuploadandvalidate: 'orchestration/benposuploadandvalidate',
        exportViolationReport:'orchestration/exportViolationReport',

        benposuploadandvalidate: 'orchestration/benposrerun',
        exportReport:'orchestration/exportReport',
        getRestrictedeReport:'orchestration/getRestrictedeReport',
        GetTradePlanReport: 'orchestration/GetTradePlanReport',
        exportDisclosurReport:'orchestration/exportDisclosurReport',
        getIssuedAndDisposalSecuritiesReportAsync:'orchestration/getIssuedAndDisposalSecuritiesReportAsync',
        getChangeInDPOrInsiderReport:'orchestration/getChangeInDPOrInsiderReport',
        TIReport:'orchestration/TIReport',
        getUPSISharingReport:'orchestration/getUPSISharingReport'
      }
    },
    authentication: {
      baseUrl: 'authentication/api/',
      api: {
        ping: 'auth/ping',
        windowslogin: 'auth/windowslogin',
        formslogin: 'auth/formslogin',
        forgotPassword: 'forgotpassword',
        verifyResetCode: 'auth/VerifyResetCode',
        resetPassword: 'auth/ResetPassword',
      }
    },
    cosecdocument: {
      baseUrl: 'document/api/',
      api: {
        ping: 'ping',
        getDocumentById: 'document/GetDocumentById',
        getAllDocuments: 'document/getalldocuments',
        saveDocument: 'document/adddocument',
        saveOtherDocument: 'document/savedocument',
        editDocument: 'document/updatedocumentmasterbyid',
        getVersionListById: 'document/getallversionsfordocument',
        downloadFile: 'document/download',
        getFormat: 'document/getFormat',
        updateDocument: 'document/updatedocument',
        deleteDocument: 'document/delete',
        getTemplates: 'DocumentFormat/getdocumentformat',
        //downloadFileById: 'document/download',
        readTemplateDocument: 'document/readDocument',
        downloadFileById: 'document/DownloadFileById',
        generateZip: 'document/generateDocumentZip',
        downloadHTMLTemplateVer2: 'document/downloadHTMLTemplateVer2',
        GetDocumentMetadataById: 'Document/GetDocumentMetadataById'
      }
    },
    cosecbase: {
      baseUrl: 'cosecbase/api/',
      api: {
        ping: 'goldenmaster/ping',
        getAllGoldenRecords: 'goldenmaster/getallgoldenrecords',
        goldenRecordBulkUpload: 'goldenmaster/bulkupload',
        updategoldenrecord: 'goldenmaster/updategoldenrecord',
        uploadPackage: 'CoSecPackage/upload',
        getPackageDetails: 'CoSecPackage/listpackages',
        downloadPackage: 'CoSecPackage/download',
        getDownloadPackageList: 'CoSecPackage/PackageDownloadList',
        getProcessFrameworkQuestions: 'ProcessFramework/listprocessframework',
        submitProcessFrameworkQuestions: 'ProcessFramework/updateprocessframework',
        getextendedgoldenproducts: 'goldenmaster/getextendedgoldenproducts',
        getAuditTrail: 'audittrail/getallauditdata',
        getReleaseList: 'releasemanagement/getReleaseList',
        updateRelease: 'releasemanagement/updateRelease',
        getDeltaList: 'releasemanagement/getDeltaList',
        generateDelta: 'releasemanagement/generateDelta',
        updateClientDelta: 'releasemanagement/updateClientDelta',
        getClientScopeList: 'releasemanagement/getClientScopeList',
        updateClientScope: 'releasemanagement/updateClientScope',
        getNotification: 'notification/GetNotifications',
        addUpdateNotification: 'notification/addUpdateNotification',
        addUpdateNotificationforgotPassword: 'notification/addUpdateNotificationforgotPassword'
      }
    },
    workflow: {
      baseUrl: 'workflow/api/',
      api: {
        ping: 'workflow/ping',
        listworkflows: 'workflow/getallworkflows',
        updateworkflow: 'workflow/updateworkflow',
        addUpdateWorkflow: 'workflow/addUpdateWorkflow',
        getWorkflow: 'workflow/getWorkflow',
        deleteWorkflow: 'workflow/deleteWorkflow',
        getWorkflowActions: 'workflow/getworkflowactionforrole'
      }
    },
    codesAndPolicies: {
      baseUrl: 'clientuser/api/',
      api: {
        getAllCodesPolicies: 'codespolicies/getAllCodesPolicies',
        updateCodePolicyDetails: 'codespolicies/updateCodePolicyDetails',
        getAllKeyConsiderationsForPolicy: 'codespolicies/getAllKeyConsiderationsForPolicy',
      }
    },
    archivesRepository: {
      baseUrl: 'clientuser/api/',
      api: {
        getArchivesRepositoryDetails: 'ArchivesRepository/getArchivesRepositoryDetails',
        updateArchivesRepositoryDetails: 'ArchivesRepository/updateArchivesRepositoryDetails',
      }
    },
    ruleEngine: {
      baseUrl: 'ruleEngine/api/',
      api: {
        listRules: 'ruleEngine/listrules',
        getRule: 'ruleEngine/getrule',
        addUpdateRule: 'ruleEngine/updaterule',
      }
    },
    notification: {
      baseUrl: 'cosecbase/api/CoSecNotification/',
      api: {
        saveNotification: 'savenotification',
        notificationList: 'listnotifications',
        clearNotifications: 'deletenotification',
        getNotification: 'getnotification',
        updateNotification: 'updatenotifications'
      }
    },
    onboarding: {
      baseUrl: 'clientuser/api/',
      api: {
        validateCIN: 'client/validateCIN',
        getClientDetails: 'client/getClientDetails',
        getMCAClientDetails: 'client/getMCAClientDetails',
        updateBasicClientDetails: 'client/updateBasicClientDetails',
        listRoles: 'role/listroles',
      }
    },

    termsAndConditions: {
      baseUrl: 'clientuser/api/',
      api: {
        updateTermsAndCondition: "client/UpdateTermsAndCondition",
        getTermsAndCondition: "client/GetAllTermsAndCondition",
        updateUserversion: "users/UpdateUserVersion"

      }
    },

    documentFormat: {
      baseUrl: 'Document/api/',
      api: {
        getTemplates: 'DocumentFormat/getdocumentformat',
      }
    },
    companyDetails: {
      baseUrl: 'clientuser/api/',
      api: {
        getCompanyData: 'client/getClientDetails',
        saveCompanyData: 'client/updateClientDetails',
        getallclients:'client/getallclients',
        updateBusinessServices: 'client/updateBusinessServices',
        saveMeetingFormat: 'client/updateMeetingFormat',
        saveListedSecurity: 'client/updateListedSecurities',
        getpoitvariables: 'client/getpoitvariables',
        getcurrentthresholds: 'client/getcurrentthresholds',
      },
    },
    capitalstructure: {
      baseUrl: 'clientuser/api/',
      api: {
        getPaidUpCapital: 'capitalstructure/GetPaidUpCapital',
        updatePaidUpCapital: 'capitalstructure/UpdatePaidUpCapital',
        validateDPInsiderSecurityDetails: 'capitalstructure/ValidateCapitalStructureWithDPInsiderData'
      },
    },
    userDetails: {
      baseUrl: 'clientuser/api/',
      user: {
        listusers: 'users/listusers',
        updateuser: 'users/updateuser',
        updateassignments: 'users/UpdateScopeByUser',
        lstuserassignments: 'users/GetScopeByUser',
        updateFormDRequired: 'Users/updateFormDRequired',
        getCOList: 'Users/getCOList',
        logout:'users/logout'
      },
      role: {
        getrole: 'role/getrole',
        listroles: 'role/listroles',
      },
    },
    kmp: {
      baseUrl: 'clientuser/api/',
      api: {
        getKMPDetails: 'Users/getKMPDetails',
        updateSignatoryDetails: 'Users/updateSignatoryDetails',
        updateDPDetails: 'Users/updateDPDetails',
        updateDPIRDetails: 'Users/updateDPIRDetails',
        updateDPMFRDetails: 'Users/updateDPMFRDetails',
        updateDPIRList: 'Users/updateDPIRList',
        updateInsiderIRDetails: 'Users/updateInsiderIRDetails',
        updateInsiderDetails: 'Users/updateInsiderDetails',
        updateInsiderList: 'Users/updateInsiderList',
        updateDPMFRList: 'Users/updateDPMFRList',
        updatePractisingProfessionalDetails: 'Users/updatePractisingProfessionalDetails',
        updateAssociatedCompanies: 'Users/updateAssociatedCompanies',
        updateOneTimeDisclosureDetails: 'Users/updateOneTimeDisclosureDetails',
        getdduserslist: 'Users/getdduserslist',
        getdpinsiderlist: 'Users/getdpinsiderlist',
        updateDPSecurityDetailsVer2: 'Users/updateDPSecurityDetailsVer2',
        updateSecurityDetailsOfDPs: 'Users/UpdateSecurityDetailsOfDPs',
        getDPReports: 'Users/getDPReport',
        getInsiderReports: 'Users/getInsiderReport',
        getChangeInDPOrInsiderReport: 'Users/getChangeInDPOrInsiderReport',
        getListOfUsersByRoleName: 'Users/getListOfUsersByRoleName',
        UpdateDarkMode: 'Users/UpdateDarkMode',
        BulkUploadDetails: 'Users/BulkUploadDetails'
      }
    },
    holdingSubsidiary: {
      baseUrl: 'clientuser/api/',
      api: {
        GetHoldingSubsidiary: 'holdingsubsidiary/GetHoldingSubsidiary',
        UpdateHoldingSubsidiary: 'holdingsubsidiary/UpdateHoldingSubsidiary/',
      },
    },
    meetingDetails: {
      baseUrl: 'clientuser/api/',
      api: {

        getMeetingDetails: 'meeting/getMeetingDetails/',
        addupdateMeetingDetails: 'meeting/addupdateMeetingDetails/',
      },
    },
    tasklist: {
      baseUrl: 'tasklist/api/',
      api: {
        getAllTransactionGroups: 'tasklist/getAllTransactionGroups',
        getAllTaskByGroup: 'tasklist/getAllTaskByGroup',
        updateTransactionGroup: 'tasklist/updateTransactionGroup',
        deleteTransactionGroup: 'tasklist/deleteTransactionGroup'
      }
    },
    poit: {
      baseUrl: 'poit/api/',
      api: {
        AddIOSGeneratedTradeDetails : 'TradeDetails/AddIOSGeneratedTradeDetails',
        savePreclearance: 'TradeDetails/addtradedetails',
        getPreclearanceDetails: 'TradeDetails/getpreclearancetradedetails',
        GetTradeDetails: 'TradeDetails/GetTradeDetails',
        getPreClearanceTradeList: 'TradeDetails/GetTradeDetails',
        getPreClearenceStatusCount: 'TradeDetails/getPreClearenceStatusCount',
        getBalanceStatements: 'TradeDetails/getbalancestatements',
        updatetradedetailsinfo: 'TradeDetails/updatetradedetailsinfo',
        updateTradingHolidayList: 'tradeholiday/updatetradeholiday',
        getTradingHolidayList: 'tradeholiday/listtradeholidays',
        getDuedateOnDuration: 'tradeholiday/getDuedateOnDuration',
        getViolationDetails: 'Dashboard/getViolationList',
        updateViolationDetails: 'Dashboard/updateViolationList',
        getDeclarationItems: 'TradeDetails/getDeclarationItems',
        saveDeclarationPoints: 'TradeDetails/saveDeclarationPoints',
        restrictedList: 'RestrictedList/listrestrictedlists',
        updateRestriction: 'RestrictedList/updaterestrictedlist',
        submitRestriction: 'RestrictedList/submitrestrictedlist',
        disclosureList: 'disclosure/listdisclosures',
        getDisclosurReport: 'disclosure/getDisclosurReport',
        getDisclosureForCo: 'disclosure/getDisclosureForCo',
        updatedisclosureList: 'disclosure/updatedisclosure',
        updateSubmissionDate: 'disclosure/updateSubmissionDate',
        listtradingwindowclosures: 'TradingWindowClosure/listtradingwindowclosures',
        listtradingwindowclosuresforpc: 'TradingWindowClosure/listtradingwindowclosuresforpc',
        listupsisharingsawaiting: 'UPSISharing/listupsisharingsawaiting',
        listupsisharings: 'UPSISharing/listupsisharings',
        listupsisharingsaudittrail: 'UPSISharing/listupsisharingsaudittrail',
        listupsisharingsForTWC: 'UPSISharing/listupsisharingsForTWC',
        getTradingWindowClosuresDate: 'TradingWindowClosure/getTradingWindowClosuresDate',
        listtradingwindowclosuresyears: 'TradingWindowClosure/listtradingwindowclosuresyears',
        updatetradingwindowclosure: 'TradingWindowClosure/updatetradingwindowclosure',
        listissueofsecuritys: 'IssueOfSecurity/listissueofsecuritys',
        updateissueofsecurity: 'IssueOfSecurity/updateissueofsecurity',
        getissueofsecurity: 'IssueOfSecurity/getissueofsecurity',
        listupsiyears: 'UPSISharing/listupsiyears',
        updateupsisharing: 'UPSISharing/updateupsisharing',
        updateupsisharingaudittrail: 'UPSISharing/updateupsisharingaudittrail',
        getUndertakingItems: 'TradeDetails/getUndertakingItems',
        getcurrentopentasksforuserrole: 'PoITWorkflow/getcurrentopentasksforuserrole',
        getContraPreClerance: 'TradeDetails/getContraPreClerance',
        getTradeSecuritiesDetails: 'TradeDetails/getTradeSecuritiesDetails',
        gettradedetailsbyId: 'TradeDetails/gettradedetailsbyId',
        getPreclearanceOrTradeDetails: 'TradeDetails/gettradedetailsbyId',
        updatetradedetailslockedBy: 'TradeDetails/updatetradedetailslockedBy',
        deletetradedetails: 'TradeDetails/deletetradedetails',
        getPendinglistformcforco: 'disclosure/pendingpublishlistformcforco',
        getPreClearanceReport:'TradeDetails/getPreClearanceReport',
        getUPSISharingReport:'Reports/getUPSISharingReport',
        getViolationReport:'Reports/getViolationReports',
        getTWCReport:'Reports/getTWCReport',
        getBenPosReport:'Reports/getBenPosReport',
        getunapproveddpsecurity: 'TradeDetails/Getunapproveddpsecurity',
        getQuarterlyTradeDetails: 'TradeDetails/getQuarterlyTradeDetails',
        getBenposVersion:'Reports/getBenposVersion',
        getBenposYearFilter:'Reports/getBenposYearFilter',
      }
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
