import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/services/env.service';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { ToastMessageService } from './toast-message.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentFetchService {

  private readonly documentBaseAPI = this.env.baseUrl + environment.services.cosecdocument.baseUrl;

  constructor(
    private httpService: HttpService,
    private env: EnvService,
    private toastMessageService: ToastMessageService,
  ) { }

  public base64ToArrayBuffer1(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    } return bytes;
  }

  public saveByteArray(reportName, byte, MIME_TYPE) {
    const blob = new Blob([byte], { type: MIME_TYPE });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    link.click();
  }

  public saveBlob(reportName, blob) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    link.click();
  }

  // Sample Input
  // {
  //   documentName: '',
  //   documentId: '',
  // }
  public getDocument(data: any, cb: any) {
    const url = environment.services.cosecdocument.api.getDocumentById + '/' + data.documentId;
    this.httpService.postDownload(this.documentBaseAPI, url, {}).subscribe((result: any) => {
      this.saveBlob(data.documentName, result);
      cb();
    }, (error) => {
      if (error.status === 400 || error.status === 500) {
        this.toastMessageService.showErrorMessage('Unable to download file. Please contact administrator');
      } else {
        this.toastMessageService.showErrorMessage('Unable to download file. Please try again.');
      }
      cb();
    });
  }

}
