export class ApiResponseCode {
	static success = 200;
	static error = 400;
	static notFound = 404;
	static unAuthorized = 401;
	static serverError = 500;
}



