import { NG_VALIDATORS, FormControl, ValidatorFn, Validator, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { Directive, Input } from '@angular/core';
@Directive({
    selector: '[AlphanumericValueValidator][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: AlphanumericValidatorDirective,
            multi: true
        }
    ]
})
export class AlphanumericValidatorDirective implements Validator {
    validator: ValidatorFn;
    ctrlName: string;
    @Input()
    set Control(val: string) {
        this.ctrlName = val;
    }
    get Control() {
        return this.ctrlName;
    }
    constructor() {

    }
    validate(control: AbstractControl): ValidationErrors {
        const ctrl = new FormControl(control.value, Validators.pattern('^[a-zA-Z0-9 _.-]*$'));

        if (ctrl.invalid) {
            return {
                AlphanumericValueValidator: {
                    valid: false
                }
            }
        }
        return null;
    }





}