import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterService } from '../../../shared/services/router.service';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { ToastMessageService } from '../../../shared/services/toast-message.service';
import { LoginService } from '../../services/login.service';
import * as _ from 'lodash';
// tslint:disable
@Component({
  selector: 'app-forgot-password',
  styleUrls: ['./forgot-password.component.scss'],
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  public flag : boolean;
  public openEmailPopUp: boolean;
  public UserEmail: string;
  public forgotPassword = { 'Email': null };
  public showSentMessage: boolean ;
  public subscriptions:any[];
  public currentYear :any = new Date().getFullYear();
  public loader: boolean;
  constructor(
    private routerService: RouterService, 
    private loginService: LoginService, 
    public router: Router, 
    public toastMessageService: ToastMessageService,
    ) { }


  public authenticate():any {
    this.loader = true;
    this.flag = false;
    let isAlive = true;
    this.subscriptions.push(this.loginService.authUserDetailsByEmail({Email:this.forgotPassword.Email}).pipe().subscribe((result:any) => {
      if (result) {
        isAlive = false;
            this.showSentMessage = true;
        //console.log(this.showSentMessage);
        this.loader = false;
      } else {
        this.loader = false;
        this.toastMessageService.showErrorMessage("Invalid user details.");
        this.flag = true;
      }
    },
      error => { 
        this.loader = false;
        this.toastMessageService.showErrorMessage("Invalid user details.");
      }
    ));
  }
  public cancelForgotPassword():any {
    this.routerService.RedirectLogin();
  }


  public ngOnInit():any {
    this.UserEmail = null;
    this.subscriptions = [];
    this.openEmailPopUp = false;
    this.flag = false;
    this.loader = false;
    this.showSentMessage = false;
  }

 public redirectToresetPage(Email:any, setResetCaseNo:number):any {
      this.routerService.RedirectResetPassword(Email, setResetCaseNo);
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, sub => sub.unsubscribe());
  }
}


