import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IToken } from '../../shared/models/token';
import { IUser } from '../../shared/models/user';
import { IGoldenMaster } from '../../shared/models/goldenmaster';
import { LocalStorageService } from './local-storage.service';
import { IUserAssignment } from '../models/userassignment';
import { IClient } from '../models/client';

@Injectable({
	providedIn: 'root',
  })
export class SessionStorageService {

	private userAuthDetailsBehaviorSubject = new BehaviorSubject<IToken>(null);
	private userDetailsBehaviorSubject = new BehaviorSubject<IUser>(null);

	private moduleListBehaviorSubject = new BehaviorSubject<Array<IGoldenMaster>>(null);
	private countryDetailsBehaviorSubject = new BehaviorSubject<Array<IGoldenMaster>>(null);
	private companyTypesBehaviorSubject = new BehaviorSubject<Array<IGoldenMaster>>(null);
	private companyEssentialsBehaviorSubject = new BehaviorSubject<Array<IGoldenMaster>>(null);
	private meetingEssentialsBehaviorSubject = new BehaviorSubject<Array<IGoldenMaster>>(null);
	private coSecTransactionsBehaviorSubject = new BehaviorSubject<Array<IGoldenMaster>>(null);
	private fieldsInventoryBehaviorSubject = new BehaviorSubject<Array<IGoldenMaster>>(null);


	private clientMasterDetailsBehaviorSubject = new BehaviorSubject<IClient>(null);
	private userAssignmentsDetailsBehaviorSubject = new BehaviorSubject<Array<IUserAssignment>>(null);
	private screenTypeBehaviourSubject = new BehaviorSubject<string>(null);
	private offeringNameBehaviourSubject = new BehaviorSubject<string>(null);
	private currentAssignmentDetailsBehaviorSubject = new BehaviorSubject<IUserAssignment>(null);
	private currentCompanyBehaviourSubject = new BehaviorSubject<string>(null);
	private companyModuleListBehaviourSubject = new BehaviorSubject<string>(null);
	private poitVariablesBehaviourSubject = new BehaviorSubject<string>(null);
	private dateFormatBehaviourSubject = new BehaviorSubject<string>(null);

	constructor(private localStorageService: LocalStorageService) {
		if (this.localStorageService.userDetails) {
			this.userDetailsBehaviorSubject.next(this.localStorageService.userDetails);
		}
		if (this.localStorageService.moduleList) {
			this.moduleListBehaviorSubject.next(this.localStorageService.moduleList);
		}
		if (this.localStorageService.countryListDetails) {
			this.countryDetailsBehaviorSubject.next(this.localStorageService.countryListDetails);
		}
		if (this.localStorageService.companyTypes) {
			this.companyTypesBehaviorSubject.next(this.localStorageService.companyTypes);
		}
		if (this.localStorageService.companyEssentials) {
			this.companyEssentialsBehaviorSubject.next(this.localStorageService.companyEssentials);
		}
		if (this.localStorageService.meetingEssentials) {
			this.meetingEssentialsBehaviorSubject.next(this.localStorageService.meetingEssentials);
		}
		if (this.localStorageService.coSecTransactions) {
			this.coSecTransactionsBehaviorSubject.next(this.localStorageService.coSecTransactions);
		}
		if (this.localStorageService.fieldsInventory) {
			this.fieldsInventoryBehaviorSubject.next(this.localStorageService.fieldsInventory);
		}
		if (this.localStorageService.clientMasterList) {
			this.clientMasterDetailsBehaviorSubject.next(this.localStorageService.clientMasterList);
		}
		if (this.localStorageService.userAssignmentsDetails) {
			this.userAssignmentsDetailsBehaviorSubject.next(this.localStorageService.userAssignmentsDetails);
		}
		if (this.localStorageService.screenType) {
			this.screenTypeBehaviourSubject.next(this.localStorageService.screenType);
		}
		if (this.localStorageService.offeringName) {
			this.screenTypeBehaviourSubject.next(this.localStorageService.offeringName);
		}
		if (this.localStorageService.currentAssignmentDetails) {
			this.currentAssignmentDetailsBehaviorSubject.next(this.localStorageService.currentAssignmentDetails);
		}
		if (this.localStorageService.currentCompany) {
			this.currentCompanyBehaviourSubject.next(this.localStorageService.currentCompany);
		}
		if (this.localStorageService.companyModuleList) {
			this.companyModuleListBehaviourSubject.next(this.localStorageService.companyModuleList);
		}
		if (this.localStorageService.dateFormat) {
			this.dateFormatBehaviourSubject.next(this.localStorageService.dateFormat);
		}
	}

	public flushOnLogout(): void {
		this.setUserAuthDetails(null);
		this.setUserDetails(null);
		this.setUserAssignmentsDetails(null);
		this.setCurrentAssignmentDetails(null);
		this.setOfferingName('');

		this.setCountryListDetails(null);
		this.setModuleList(null);
		this.setCompanyTypes(null);
		this.setCompanyEssentials(null);
		this.setMeetingEssentials(null);
		this.setTransactions(null);
		this.setCurrentCompany(null);
		this.setCompanyModuleList(null);
		this.setFieldsInventory(null);


	}

	// User Auth Detailss
	get userAuthDetails(): Observable<IToken> {

		return this.userAuthDetailsBehaviorSubject.asObservable();
	}

	public setUserAuthDetails(value: IToken) {
		if (value === null) {
			this.localStorageService.authToken = null;
		} else {
			this.localStorageService.authToken = value.AuthToken;
		}
		this.userAuthDetailsBehaviorSubject.next(value);
	}

	// User Details
	get userDetails(): Observable<IUser> {
		return this.userDetailsBehaviorSubject.asObservable();
	}

	public setUserDetails(value: IUser) {
		this.localStorageService.userDetails = value;
		this.userDetailsBehaviorSubject.next(value);
	}

	// Client List

	get clientMasterListDetails(): Observable<IClient> {
		return this.clientMasterDetailsBehaviorSubject.asObservable();
	}

	public setClientMasterListDetails(value: IClient) {
		//todo
		// this.localStorageService.clientMasterList = value;
		this.clientMasterDetailsBehaviorSubject.next(value);
	}

	// Golden Master List Details


	get countryListDetails(): Observable<Array<IGoldenMaster>> {
		return this.countryDetailsBehaviorSubject.asObservable();
	}

	public setCountryListDetails(value: Array<IGoldenMaster>) {
		//todo
		// this.localStorageService.countryListDetails = value;
		this.countryDetailsBehaviorSubject.next(value);
	}

	get companyTypes(): Observable<Array<IGoldenMaster>> {
		return this.companyTypesBehaviorSubject.asObservable();
	}

	public setCompanyTypes(value: Array<IGoldenMaster>) {
		//todo
		// this.localStorageService.countryListDetails = value;
		this.companyTypesBehaviorSubject.next(value);
	}

	get companyEssentials(): Observable<Array<IGoldenMaster>> {
		return this.companyEssentialsBehaviorSubject.asObservable();
	}

	public setCompanyEssentials(value: Array<IGoldenMaster>) {
		//todo
		// this.localStorageService.countryListDetails = value;
		this.companyEssentialsBehaviorSubject.next(value);
	}

	get meetingEssentials(): Observable<Array<IGoldenMaster>> {
		return this.meetingEssentialsBehaviorSubject.asObservable();
	}

	public setMeetingEssentials(value: Array<IGoldenMaster>) {
		//todo
		// this.localStorageService.countryListDetails = value;
		this.meetingEssentialsBehaviorSubject.next(value);
	}

	get coSecTransactions(): Observable<Array<IGoldenMaster>> {
		return this.coSecTransactionsBehaviorSubject.asObservable();
	}

	public setTransactions(value: Array<IGoldenMaster>) {
		//todo
		// this.localStorageService.countryListDetails = value;
		this.coSecTransactionsBehaviorSubject.next(value);
	}

	get fieldsInventory(): Observable<Array<IGoldenMaster>> {
		return this.fieldsInventoryBehaviorSubject.asObservable();
	}

	public setFieldsInventory(value: Array<IGoldenMaster>) {
		//todo
		// this.localStorageService.countryListDetails = value;
		this.fieldsInventoryBehaviorSubject.next(value);
	}

	get moduleList(): Observable<Array<IGoldenMaster>> {
		return this.moduleListBehaviorSubject.asObservable();
	}

	public setModuleList(value: Array<IGoldenMaster>) {
		//todo
		// this.localStorageService.othersListDetails = value;
		this.moduleListBehaviorSubject.next(value);
	}

	// UserAssignment Role Tenant Details
	get userAssignmentsDetails(): Observable<Array<IUserAssignment>> {
		return this.userAssignmentsDetailsBehaviorSubject.asObservable();
	}
	public setUserAssignmentsDetails(value: Array<IUserAssignment>) {
		// If required then only store in local storage
		this.localStorageService.userAssignmentsDetails = value;
		this.userAssignmentsDetailsBehaviorSubject.next(value);
	}

	// UserAssignment Role Tenant Details
	get currentAssignmentDetails(): Observable<IUserAssignment> {
		return this.currentAssignmentDetailsBehaviorSubject.asObservable();
	}
	public setCurrentAssignmentDetails(value: IUserAssignment) {
		// If required then only store in local storage
		this.localStorageService.currentAssignmentDetails = value;
		this.currentAssignmentDetailsBehaviorSubject.next(value);
	}

	public getRole() {
		let retRole = '';
		this.userAssignmentsDetails.subscribe((result) => {
			if (result) {
				result.map(function (item) {
					retRole = item.Role.RoleName;
				});
			}
		});
		return retRole;
	}
	// Screen Type
	get getScreenType(): Observable<string> {
		return this.screenTypeBehaviourSubject.asObservable();
	}

	public setScreenType(value: string) {
		this.localStorageService.screenType = value;
		this.screenTypeBehaviourSubject.next(value);
	}

	// Offering Name
	get offeringName(): Observable<string> {
		return this.offeringNameBehaviourSubject.asObservable();
	}

	public setOfferingName(value: string) {
		this.localStorageService.offeringName = value;
		this.offeringNameBehaviourSubject.next(value);
	}

	// Current Company
	get currentCompany(): Observable<string> {
		return this.currentCompanyBehaviourSubject.asObservable();
	}

	public setCurrentCompany(value: string) {
		this.localStorageService.currentCompany = value;
		this.currentCompanyBehaviourSubject.next(value);
	}

	// Company Module List
	get companyModuleList(): Observable<string> {
		return this.companyModuleListBehaviourSubject.asObservable();
	}

	public setCompanyModuleList(value: any) {
		this.localStorageService.companyModuleList = value;
		this.companyModuleListBehaviourSubject.next(value);
	}


	// POIT Variables
	get POITVariables(): Observable<any> {
		return this.poitVariablesBehaviourSubject.asObservable();
	}

	public setPOITVariables(value: any) {
		this.poitVariablesBehaviourSubject.next(value);
	}

	// Date Format
	get getDateFormatData(): Observable<string> {
		return this.dateFormatBehaviourSubject.asObservable();
	}

	public setDateFormat(value: string) {
		this.localStorageService.dateFormat = value;
		this.dateFormatBehaviourSubject.next(value);
	}

	public getDateFormat() {
		let format = 'dd-M-yy';
		this.getDateFormatData.subscribe((result) => {
			if (result) {
				if(result){
					format = result;
				}
			}
		});
		return format;
	}
}
