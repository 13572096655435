import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { HttpService } from '../../shared/services/http.service';
import { Login } from '../../shared/models/login';

import { config, Observable } from 'rxjs';
import { IUser } from '../../shared/models/user';
import { IToken } from 'src/app/shared/models/token';
import { EnvService } from 'src/app/services/env.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { finalize } from '../../../../node_modules/rxjs/operators';
import { PartialObserver } from 'rxjs/internal/types';
@Injectable({
	providedIn: 'root',
  })
export class LoginService {
	public getewayUrl: String = environment.services.gatewayUrl;
	private readonly apiSecurityBaseUrl;
	private readonly orchestrationUrl = this.env.baseUrl + environment.services.Orchestration.baseUrl;
	private readonly apiprojectUrl = this.env.baseUrl + environment.services.cosecbase.baseUrl;
	private readonly clientUserUrl = this.env.baseUrl + environment.services.userDetails.baseUrl;
	private readonly termsProjectUrl = this.env.baseUrl + environment.services.termsAndConditions.baseUrl;

	constructor(private httpService: HttpService, private env: EnvService, private notificationService:NotificationService,) {
    this.apiSecurityBaseUrl =  env.baseUrl + environment.services.authentication.baseUrl;
   }

   public authUserDetailsByEmail(data: any): Observable<any> {
    const url = environment.services.Orchestration.Orchestration.getAuthByEmail;//.replace('{userEmail}', userEmail);
    return this.httpService.post<any>(this.orchestrationUrl, url, data);
  }
  
  public addUpdateNotificationforgotPassword(data: any): any {
    const url = environment.services.cosecbase.api.addUpdateNotificationforgotPassword;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
	public ping(): Observable<any> {
		const url = environment.services.authentication.api.ping;
		return this.httpService.get<any>(this.apiSecurityBaseUrl, url);
	}

	public windowslogin(): Observable<IToken> {
		const baseurl = this.env.baseSecurityUrl;
		const url = environment.services.authentication.api.windowslogin;
		return this.httpService.get<IToken>(baseurl, url);
	}

	public formslogin(credentials: Login): Observable<IToken> {
		const url = environment.services.authentication.api.formslogin;    
    	const baseurl = this.env.baseSecurityUrl;
		return this.httpService.post<IToken>(baseurl, url, credentials);
	}

	public getTermsAndCondition(data: any, config) {
		const url = environment.services.termsAndConditions.api.getTermsAndCondition;
		return this.httpService.post2<any>(this.termsProjectUrl, url, data, config);
	  }

	public updateUserversion(data: any) {
		const url = environment.services.termsAndConditions.api.updateUserversion;
		return this.httpService.post<any>(this.termsProjectUrl, url, data);
	  }
	  public logout(data: any) {
		const url = environment.services.userDetails.user.logout;
		return this.httpService.post<any>(this.clientUserUrl, url, data);
	  }
	public forgotPassword(email: string): Observable<IUser> {
		const url = environment.services.authentication.api.forgotPassword;
		return this.httpService.post<IUser>(this.apiSecurityBaseUrl, url, email);
	}

	public verifyResetCode(data: {}): Observable<any> {
		const url = environment.services.authentication.api.verifyResetCode;
		return this.httpService.post<any>(this.apiSecurityBaseUrl, url, data);
	}

	public resetPassword(data: {}): Observable<any> {
		const url = environment.services.authentication.api.resetPassword;
		return this.httpService.post<any>(this.apiSecurityBaseUrl, url, data);
	}

	public sendEmail(mailData: any): boolean {
		const getewayUrl = (this.getewayUrl).toLocaleLowerCase().replace('/gateway', '');
		let result: boolean = false;
		mailData.MailData.ClientName = mailData.MailData.ClientName;
		mailData.MailData.Date = new Date();
		mailData.MailData.BaseUrl = this.getewayUrl;
		mailData.MailData.link = this.getewayUrl;
		mailData.MailData.URL = this.getewayUrl + ((mailData.MailData.URL) ? mailData.MailData.URL : '');
		mailData.MailData = JSON.stringify(mailData.MailData);
		const multiEmailObserver: PartialObserver<any> = {
		  complete: (): void => {
		  },
		  error: (error: Error): void => {
			result = false;
		  },
		  next: (resultData: any): void => {
			if (resultData) {
			  result = true;
			}
		  }
		};
		this.addUpdateNotificationforgotPassword([mailData]).pipe(finalize(() => {
		  result = false;
		})).subscribe(multiEmailObserver);
	
		return result;
	  }
}
