import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
    name: 'uniqueOf'
})
export class RemovePipe implements PipeTransform {
   transform(value: any): any {
        if (value !== undefined && value !== null) {
            return _.uniqBy(value, 'actionname');
        }
        return value;
    }
}
