import { Component, OnInit, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { RouterService } from '../../../../../shared/services/router.service';
import { DataService } from '../../../../../shared/services/data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// lodash
import * as _ from 'lodash';

@Component({
  selector: 'app-doormatpopupcoseceyadmin',
  templateUrl: './doormatpopupcoseceyadmin.component.html',
  styleUrls: ['./doormatpopupcoseceyadmin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DoormatPopupCoSecEYAdminComponent implements OnInit, OnDestroy {

  // @Output() selectedDialogAction = new EventEmitter<any>();

  constructor(
    private routerService: RouterService,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  callClosePopup() {
    this.dataService.closeMenuBox();
  }

  callProfilePage() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToProfilePage();
  }

  callClientOnboarding() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToClientOnboarding();
  }

  callDocumentMgmt() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToDocumentMgmt();
  }

  callUserMaster() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToUserMaster();
  }

  callRuleEngine() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToRuleEngine();
  }

  callTaskTemplate() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToTaskTemplateMain();
  }

  callWorkflowMgmt() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToWorkflowMgmt();
  }

  callGoldenMaster() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToGoldenMaster();
  }

  callMeetingMaster() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToMeetingMaster();
  }
  callNotificationConfig() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToNotificationConfig();
  }

}
