import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/services/http.service';
import { HttpParams } from '@angular/common/http';
import { EnvService } from 'src/app/services/env.service';
import { IDocumentUpload } from 'src/app/shared/models/document-upload';
import { IPoitvariables } from 'src/app/shared/models/poitvariables';

@Injectable({
  providedIn: 'root'
})
export class OnboardingDetailsService {

  private readonly apiprojectUrl = this.env.baseUrl + environment.services.onboarding.baseUrl;
  private readonly documentBaseAPI = this.env.baseUrl + environment.services.cosecdocument.baseUrl;
  private readonly apiprojectUrlOrch = this.env.baseUrl + environment.services.Orchestration.baseUrl;

  constructor(private httpService: HttpService, private env: EnvService) { }
  getallclients() {
    const url = environment.services.companyDetails.api.getallclients;
    return this.httpService.get<any>(this.apiprojectUrl, url);
  }

  // validateCIN(data: any) {
  //   const url = environment.services.onboarding.api.validateCIN;
  //   return this.httpService.post<any>(this.apiprojectUrl, url, data);
  // }
  validateCIN(data:any) {
    const url = environment.services.Orchestration.client.validateCIN;
    return this.httpService.post<any>(this.apiprojectUrlOrch, url, data);
  }
  getClientDetails(data: any) {
    const url = environment.services.onboarding.api.getClientDetails;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  getClientMCADetails(data: any) {
    const url = environment.services.onboarding.api.getMCAClientDetails;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  getTemplates(data: any) {
    const url =  environment.services.cosecdocument.api.getTemplates;
    return this.httpService.post<any>(this.documentBaseAPI, url, data);
  }

  updateBasicClientDetails(data: any) {
    const url = environment.services.onboarding.api.updateBasicClientDetails;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
  getCompanyDetails(data: any) {
    const url = environment.services.companyDetails.api.getCompanyData;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  getCurrentThresholds(data: any) {
    const url = environment.services.companyDetails.api.getcurrentthresholds;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }

  saveCompanyDetails(data) {
    const url = environment.services.companyDetails.api.saveCompanyData;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
  saveBusinessServices(data) {
    const url = environment.services.companyDetails.api.updateBusinessServices;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
  saveMeetingFormat(data) {
    const url = environment.services.companyDetails.api.saveMeetingFormat;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
  saveListedSecurity(data) {
    const url = environment.services.companyDetails.api.saveListedSecurity;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
  saveDocument(viewModel: IDocumentUpload) {
    const url = environment.services.cosecdocument.api.saveDocument;
    return this.httpService.post(this.documentBaseAPI, url, viewModel);
  }

  saveOtherDocument(viewModel: IDocumentUpload) {
    const url = environment.services.cosecdocument.api.saveOtherDocument;
    return this.httpService.post(this.documentBaseAPI, url, viewModel);
  }

  uploadHeaderFooterLogo(data) {
    const url = environment.services.Orchestration.client.uploadHeaderFooterLogo;
    return this.httpService.post<any>(this.apiprojectUrlOrch, url, data);
  }
  uploadHeaderFooterLogoPreview(data) {
    const url = environment.services.Orchestration.client.uploadHeaderFooterLogoPreview;
    return this.httpService.post<any>(this.apiprojectUrlOrch, url, data);
  }
  getPOITVariables(data: any) {
    const url = environment.services.companyDetails.api.getpoitvariables;
    return this.httpService.post<any>(this.apiprojectUrl, url, data);
  }
}
