import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { ToastMessageService } from 'src/app/shared/services';
import { IProgressActions } from 'src/app/shared/models/progressactions';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  fileToUpload: any;
  fileUploadModel: any[] = [];
  jsonFileContent: any;
  fileExtensions: string;
  checkExcelValidation: any;
  isFileInvalid = false;
  lstExcelFileExt = ['xls', 'xlsx'];
  // progressbar variables
  progressActions: IProgressActions[] = [];
  progressBar: boolean = false;
  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private toastMessageService: ToastMessageService
  ) {
    this.fileUploadModel = [];
  }

  ngOnInit() {
    this.fileExtensions = this.config.data.fileExtensions;
  }

  async convertToBinary(event) {
    this.progressBar = true;
    const objProgressAction = {} as IProgressActions;
    objProgressAction.ActionName = 'validateFile';
    objProgressAction.Completed = false;
    this.progressActions.push(objProgressAction);
    if (this.config.data.convertToBinaryFlag) {
      const fileName = event.files[0].name;
      const filesData = event.files[0];
      const fileExtension = fileName.slice(
        // tslint:disable-next-line: no-bitwise
        ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
      );
      if (this.fileExtensions.indexOf('.txt') > -1 && fileExtension === 'txt') {
        const _this = this;
        const reader = new FileReader();
        reader.onload = (function (f) {
          return function (e) {
            const jsonData = e.target.result;
            _this.fileToUpload = jsonData;
            _this.fileUploadModel.push({ fileToUpload: _this.fileToUpload, fileName, fileExtension });
          };
        })(filesData);
        reader.readAsText(filesData);
        _this.fileToUpload = reader;

      } else if ((this.fileExtensions.indexOf('.pdf') > -1 && fileExtension === 'pdf') ||
          (this.fileExtensions.indexOf('.jpg') > -1 && fileExtension === 'jpg') ||
          (this.fileExtensions.indexOf('.csv') > -1 && fileExtension === 'csv')) {
        const _this = this;
        const reader = new FileReader();
        reader.onload = (function (f) {
          return function (e) {
            const binaryData = e.target.result;
            const base64String = window.btoa(binaryData);
            _this.fileToUpload = base64String;
            _this.fileUploadModel.push({ fileToUpload: _this.fileToUpload, fileName, fileExtension });
          };
        })(filesData);
        reader.readAsBinaryString(filesData);
        _this.fileToUpload = reader;
      } else {
        await this.validateExceFile(event).then((res) => {
          _.forEach(this.progressActions, (action) => {
            if (action.ActionName === 'validateFile') {
              action.Completed = true;
              this.checkProgress();
            }
          });
          if (res) {
            const _this = this;
            const reader = new FileReader();
            reader.onload = (function (f) {
              return function (e) {
                const binaryData = e.target.result;
                const base64String = window.btoa(binaryData);
                _this.fileToUpload = base64String;
                _this.fileUploadModel.push({ fileToUpload: _this.fileToUpload, fileName, fileExtension });
              };
            })(filesData);
            reader.readAsBinaryString(filesData);
            _this.fileToUpload = reader;
          }
        }, reason => {
          _.forEach(this.progressActions, (action) => {
            if (action.ActionName === 'validateFile') {
              action.Completed = true;
              this.checkProgress();
            }
          });
          this.toastMessageService.showErrorMessage(reason);
        });
      }
    } else {
      const _this = this;
      const filesData = event.files[0];
      const fileName = filesData.name;
      const entitlementBulkUploadFlag = this.config.data.entitlementBulkUpload ? true : false;
      const fileExtension = fileName.slice(
        // tslint:disable-next-line: no-bitwise
        ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
      );

      await this.validateExceFile(event).then((res) => {
        _.forEach(this.progressActions, (action) => {
          if (action.ActionName === 'validateFile') {
            action.Completed = true;
            this.checkProgress();
          }
        });
        if (res) {
          this.jsonFileContent = '';
          const reader: FileReader = new FileReader();
          if (filesData !== undefined && filesData !== null) {
            reader.onload = (function (f) {
              return function (e) {
                const arrayBuffer: any = reader.result;
                const data = new Uint8Array(arrayBuffer);
                const arr = new Array();
                for (let i = 0; i !== data.length; ++i) {
                  arr[i] = String.fromCharCode(data[i]);
                }

                if (entitlementBulkUploadFlag) {
                  const bstr = arr.join('');
                  const workbook = XLSX.read(bstr, { type: 'binary', cellDates: true });
                  for (let i = 0; i < workbook.SheetNames.length; i++) {
                    const workSheetName = workbook.SheetNames[i];
                    const worksheet = workbook.Sheets[workSheetName];
                    _this.jsonFileContent = (XLSX.utils.sheet_to_json(worksheet, { raw: false }));
                    _this.fileUploadModel.push({workSheetData : (XLSX.utils.sheet_to_json(worksheet, { raw: false })),
                                                fileName, fileExtension, workSheetName });
                  }
                } else {
                  const bstr = arr.join('');
                  const workbook = XLSX.read(bstr, { type: 'binary' });
                  const workSheetName = workbook.SheetNames[0];
                  const worksheet = workbook.Sheets[workSheetName];
                  _this.jsonFileContent = (XLSX.utils.sheet_to_json(worksheet, { raw: true }));
                  _this.fileUploadModel.push({ fileToUpload: (XLSX.utils.sheet_to_json(worksheet, { raw: true })),
                                               fileName, fileExtension });
                }
              };
            })(filesData);
            reader.readAsArrayBuffer(filesData);
          }
        }
      }, reason => {
        _.forEach(this.progressActions, (action) => {
          if (action.ActionName === 'validateFile') {
            action.Completed = true;
            this.checkProgress();
          }
        });
        this.toastMessageService.showErrorMessage(reason);
      });
    }
  }

  upload() {
    this.ref.close(this.fileUploadModel);
  }
  async  validateExceFile(event) {
    const fileSize = event.files[0].size;
    const fileName = event.files[0].name;
    const fileExtension = fileName.slice(
      // tslint:disable-next-line: no-bitwise
      ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
    );
    let searchResults: any;
    let isColumnEqual = false;
    let isJsExist = false;
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      // file ext check
      if (!_.includes(this.lstExcelFileExt, fileExtension)) {
        reject('Invalid file type uploaded');
      } else if (fileSize > 1000000) {
        // file size check
        reject('Valid File Upload Size is 1MB');
      } else {
        fileReader.onload = (e) => {
          this.checkExcelValidation = fileReader.result;
          const fileData = new Uint8Array(this.checkExcelValidation);
          const fileContentArray = new Array();
          for (let i = 0; i !== fileData.length; ++i) {
            fileContentArray[i] = String.fromCharCode(fileData[i]);
          }
          const excelData = fileContentArray.join('');

          const workbook = XLSX.read(excelData, { type: 'binary' });
          for (let i = 0; i < workbook.SheetNames.length; i++) {
            const sheetName = workbook.SheetNames[i];
            const worksheet = workbook.Sheets[sheetName];
            const jsonResult: any = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: '' });
            // file content empty check
            if (jsonResult === undefined || jsonResult.length === 0) {
              this.isFileInvalid = true;
              reject('Uploaded file is empty');
            }

            // column header check

            if (jsonResult != null && jsonResult !== undefined && jsonResult.length !== 0) {
              let extractedColumns: string[] =
                (jsonResult !== null && jsonResult !== undefined && jsonResult.length !== 0) ? Object.keys(jsonResult[0]) : null;
                extractedColumns = extractedColumns.map(x => x.trim());

              // Entitlement bulk upload
              if (this.config.data.entitlementBulkUpload) {
                const matchedItem = _.find(this.config.data.excelHeader, {'WorkSheetName': sheetName});
                if (_.intersection(extractedColumns, matchedItem.WorkSheetHeader).length < matchedItem.WorkSheetHeader.length) {
                  isColumnEqual = true;
                  // this.entitlementBulkUploadFlag = true;
                  reject('Column headers missing');
                }
              } else {
                if (_.intersection(extractedColumns, this.config.data.excelHeader).length < this.config.data.excelHeader.length) {
                  isColumnEqual = true;
                  reject('Column headers missing');
                }
              }

              // file content check
              searchResults = JSON.stringify(jsonResult).match(/<script>/);
              // tslint:disable-next-line: max-line-length
              if (JSON.stringify(jsonResult).match(/</) !== null
                || JSON.stringify(jsonResult).indexOf('=') !== -1
                || JSON.stringify(jsonResult).indexOf('+') !== -1) {
                isJsExist = true;
              }
            }
            if (searchResults !== null || isJsExist) {
              this.isFileInvalid = true;
              reject('Uploaded File content invalid');
            }
            if (searchResults === null && !isJsExist && !isColumnEqual) {
              resolve(true);
            }
          }
        };
        fileReader.readAsArrayBuffer(event.files[0]);
      }
    });
  }
  private checkProgress() {
    const progressCount: number = _.filter(this.progressActions, { Completed: true }).length;
    if (progressCount === this.progressActions.length) {
      this.progressBar = false;
    }
  }
}
