import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appKeypressValidator]',

})
export class KeypressValidatorDirective {
  public keypressed;
  regex = /^[a-zA-Z0-9 _.-]*$/;

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    // console.log(e)
    if (!this.regex.test(e.target.value)) {
      e.target.value = '';
    }
    // Allow 0-9 & a-z
    // if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) ||
    //   // Allow delete, backspace, tab, escape, enter, decimal point, period(.), dash, capslock
    //   [46, 8, 9, 27, 13, 110, 190, 189, 20].indexOf(e.keyCode) !== -1 ||
    //   // Allow: Ctrl+A
    //   (e.keyCode === 65 && (e.ctrlKey)) ||
    //   // Allow: Ctrl+C
    //   (e.keyCode === 67 && (e.ctrlKey)) ||
    //   // Allow: Ctrl+V
    //   (e.keyCode === 86 && (e.ctrlKey)) ||
    //   // Allow: Ctrl+X
    //   (e.keyCode === 88 && (e.ctrlKey)) ||
    //   // Allow: shift + - (_)
    //   (e.keyCode === 189 && (e.shift)) ||
    //   // Allow: home, end, left, right
    //   (e.keyCode >= 35 && e.keyCode <= 39)) {
    //   return true;
    // } else {
    //   // console.log(e)
    //   e.target.value = '';
    //   e.preventDefault();
    //   return false;
    // }
  }
  @HostListener('keyup', ['$event']) onKeyUp(e) {
  //   // console.log(e)
  if (!this.regex.test(e.target.value)) {
    e.target.value = '';
  }
  //   // Allow 0-9 & a-z
  //   if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) ||
  //     // Allow delete, backspace, tab, escape, enter, decimal point, period(.), dash, capslock
  //     [46, 8, 9, 27, 13, 110, 190, 189, 20].indexOf(e.keyCode) !== -1 ||
  //     // Allow: Ctrl+A
  //     (e.keyCode === 65 && (e.ctrlKey)) ||
  //     // Allow: Ctrl+C
  //     (e.keyCode === 67 && (e.ctrlKey)) ||
  //     // Allow: Ctrl+V
  //     (e.keyCode === 86 && (e.ctrlKey)) ||
  //     // Allow: Ctrl+X
  //     (e.keyCode === 88 && (e.ctrlKey)) ||
  //     // Allow: shift + - (_)
  //     (e.keyCode === 189 && (e.shift)) ||
  //     // Allow: home, end, left, right
  //     (e.keyCode >= 35 && e.keyCode <= 39)) {
  //     return true;
  //   } else {
  //     // console.log(e)
  //     if (!(e.keyCode === 189 && e.shift)) {
  //       e.target.value = '';
  //     }
  //     e.preventDefault();
  //     return false;
  //   }
  }
}
