import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoggingInComponent } from './components/logging-in/logging-in.component';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page.component';
import { ForbiddenPageComponent } from './components/forbidden-page/forbidden-page.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { CodesAndPoliciesComponent } from '../zibble/cosec/client-onboarding/component/codes-and-policies/codes-and-policies.component';
import { LogoutComponent } from './components/logout/logout.component';


const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				component: LoginComponent
			},
			{
				path: 'forgot',
				component: ForgotPasswordComponent
			},
			{
				path: 'reset-password/:userEmail/:setResetCaseNo',
				component: ResetPasswordComponent
			}
			, {
				path: 'loggingin',
				component: LoginComponent
			}
			,
			{
				path: 'forbidden',
				component: ForbiddenPageComponent
			},
			{
				path: 'unauthorized',
				component: UnauthorizedPageComponent
			},
			{
				path: 'errorPage',
				component: ErrorPageComponent
			},
			{
				path: 'codesandpolicies/:isReadOnly',
				component: CodesAndPoliciesComponent
			},
			{
				path : 'logout',
				component:LogoutComponent	
			}
		
		]
	},
	{
		path: 'masteradmindashboard',
		loadChildren: '../zibble/master/admindashboard/masteradmindashboard.module#MasterAdminDashboardModule'
	},
	{
		path: 'cosecadmindashboard',
		loadChildren: '../zibble/cosec/dashboard-summary/cosecadmindashboard.module#CoSecAdminDashboardModule'
	},
	{
		path: 'clientmaster',
		loadChildren: '../zibble/master/client/clientmaster.module#ClientMasterModule'
	},
	{
		path: 'goldenmaster',
		loadChildren: '../zibble/master/golden/goldenmaster.module#GoldenMasterModule'
	},
	{
		path: 'meetingmaster',
		loadChildren: '../zibble/master/meeting/meetingmaster.module#MeetingmasterModule'
	},
	{
		path: 'usermanagement',
		loadChildren: '../zibble/cosec/usermanagement/usermanagement.module#UserManagementModule'
	},
	{
		path: 'client-onboarding',
		loadChildren: '../zibble/cosec/client-onboarding/client-onboarding.module#ClientOnboardingModule'
	},
	{
		path:'documentmanagement',
		loadChildren: '../zibble/cosec/document-management/document-management.module#DocumentManagementModule'
	},
	{
		path:'tasktemplate',
		loadChildren: '../zibble/cosec/task-template/task-template.module#TaskTemplateModule'
	},
	{
		path: 'restrictedlist',
		loadChildren: '../zibble/cosec/restricted-list/restricted-list.module#RestrictedListModule'
	},

	{
		path: 'frequently-asked-questions',
		loadChildren: '../zibble/cosec/frequently-asked-questions/frequently-asked-questions.module#FrequentlyAskedQuestionsModule'
	},
	{
		path: 'notification',
		loadChildren: '../zibble/cosec/notification/notification.module#NotificationModule'
	},
	{
		path: 'digital-database',
		loadChildren: () => import('../zibble/cosec/digital-database/digital-database.module')
		.then(m => m.DigitalDatabaseModule)
	},
	{
		path: 'upsi-sharing',
		loadChildren: () => import('../zibble/cosec/upsi-sharing/upsi-sharing.module')
		.then(m => m.UpsiSharingModule)
	},
	{
		path: 'workflowmgmt',
		loadChildren: '../zibble/cosec/workflow-manager/workflow-manager.module#WorkflowManagerModule'
	},
	{
		path: 'personal-details',
		loadChildren: '../zibble/cosec/personal-details/personal-details.module#PersonalDetailsModule'
	},
	{
		path: 'trading-plan',
		loadChildren: '../zibble/cosec/trading-plan/trading-plan.module#TradingPlanModule'
	},
	{
		path: 'trading-plan-submission',
		loadChildren: '../zibble/cosec/trading-plan-submission/trading-plan-submission.module#TradingPlanSubmissionModule'
	},
    {
		path:'poit',
		loadChildren: '../zibble/cosec/poit/poit.module#POITModule'
	},
	{
		path: 'holidaylist',
		loadChildren: '../zibble/cosec/trading-holiday-list/trading-holiday-list.module#TradingHolidayListModule'
	},
	{
		path: 'trading-window',
		loadChildren: '../zibble/cosec/trading-window/trading-window.module#TradingWindowModule'
	},
	{
		path: 'issue-of-security',
		loadChildren: '../zibble/cosec/issue-of-securities/issue-of-securities.module#IssueOfSecuritiesModule'
	},
	{
		path: 'ruleengine',
		loadChildren: '../zibble/cosec/rule-engine/rule-engine.module#RuleEngineModule'
	},
	{
		path: 'reports-formats',
		loadChildren: '../zibble/cosec/reports/poit/reports.module#ReportsModule'
	},
	{
		path: 'termsandconditionsmanager',
		loadChildren: '../zibble/cosec/terms-and-condition-manager/terms-and-condition-manager.module#TermsAndConditionManagerModule'
	}
];



@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',
	onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class CoreRoutingModule {

}
