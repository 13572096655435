import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'src/app/shared/services';
import { RouterService } from 'src/app/shared/services/router.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor(private sessionStorageService: SessionStorageService,
    private routerService: RouterService) { }

  ngOnInit() {
    this.sessionStorageService.setUserAuthDetails(null);
  }

  RedirectLogin() {
    this.routerService.RedirectLogin();
  }

}
