import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { RoleEnum } from '../constants/enum';
import { WorkFlowModules } from 'src/app/shared/constants/enum';
import { DataService } from './data.service';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class RouterService {
	private readonly extras: NavigationExtras = { skipLocationChange: true };
	previousURL;
	url: any;
	constructor(private router: Router, private dataservice: DataService) {
		this.url = [];
		this.dataservice.currentUrl.subscribe(message => {
			if (this.url[this.url.length - 1] === message) {
				this.url.pop();
			} else {
				this.url.push(this.previousURL);
			}
			this.previousURL = message;
		});
	}

	public getPerviousURL() {
		if (this.url.length) {
			if ((this.url[this.url.length - 1]) === '/poit/trade-intimation' || (this.url[this.url.length - 1]) === '/cosecadmindashboard' || (this.url[this.url.length - 1]) === '/poit/preClearence' || (this.url[this.url.length - 1]) === '/poit/viewWaiverPreClearance') {
				return WorkFlowModules.PERSONAL_DETAILS;
			} else {
				return WorkFlowModules.CLIENT_ONBOARDING;
			}
		} else {
			return WorkFlowModules.CLIENT_ONBOARDING;
		}
	}


	public RedirectResetPassword(userEmail: string , setResetCaseNo: number) {
		this.router.navigate(['/reset-password', userEmail , setResetCaseNo],
			{ skipLocationChange: true });
	}

	public RedirectLogin() {
		this.router.navigate(['/']
			, { skipLocationChange: true }
		);
	}

	public RedirectForgotPassword() {
		this.router.navigate(['/forgot']
			, { skipLocationChange: true }
		);
	}

	public RedirectLoggingIn() {
		this.router.navigate(['/loggingin'],
			this.extras);
	}

	public RedirectForbiddenPage() {
		this.router.navigate(['/forbidden'],
			this.extras);
	}

	public RedirectUnauthorizedPage() {
		this.router.navigate(['/unauthorized'],
			{ skipLocationChange: true });
	}

	public RedirectToMasterAdminDashboard() {
		this.router.navigate(['/masteradmindashboard'],
			this.extras);
	}

	public RedirectToCoSecAdminDashboard() {
		this.router.navigate(['/cosecadmindashboard'],
			this.extras);
	}

	public RedirectToProfilePage() {
		this.router.navigate(['/profilepage'],
			this.extras);
	}

	public RedirectToPersonalDetails() {
		this.router.navigate(['/personal-details/main-disclosure'],
			this.extras);
	}
	public RedirectToFormCSubmission() {
		this.router.navigate(['/personal-details/form-C-submission'],
			this.extras);
	}
	public RedirectToFormDSubmission() {
		this.router.navigate(['/personal-details/form-D-submission'],
			this.extras);
	}
	public RedirectToTradingPlan() {
		this.router.navigate(['/trading-plan'],
			this.extras);
	}
	public RedirectToTradingPlanInsider() {
		this.router.navigate(['/trading-plan/trading-plan-insider'],
			this.extras);
	}
	public RedirectToTradingPlanDP() {
		this.router.navigate(['/trading-plan/trading-plan-dp'],
			this.extras);
	}
	public RedirectToProposedTradePlanByDP() {
		this.router.navigate(['/trading-plan/proposed-trade-plan-by-dp'],
			this.extras);
	}
	public RedirectToTradingPlanSubmission() {
		this.router.navigate(['/trading-plan-submission'],
			this.extras);
	}
	public RedirectToHoldingDisclosure() {
		this.router.navigate(['/personal-details/holding-disclosure'],
			this.extras);
	}
	public RedirectToIRMFRDisclosure()
	{
		this.router.navigate(['/personal-details/ir-mfr-disclosure'],
			this.extras);
	}

	public RedirectToOneTimeDisclosure() {
		this.router.navigate(['/personal-details/one-time-disclosure'],
			this.extras);
	}
	public RedirectToDocumentMgmt() {
		this.router.navigate(['/documentmanagement'],
			this.extras);
	}
	public RedirectToNewTrade() {
		this.router.navigate(['/poit/trade-intimation'],
			this.extras);
	}
	public RedirectToRaisePreClerance() {
		this.router.navigate(['/poit/preClearence'],
			this.extras);
	}	
	// public RedirectToTradeIntimation() {
	// 	this.router.navigate(['/poit/trade-intimation'],
	// 		this.extras);
	// }
	public RedirectToPOIT() {
		this.router.navigate(['/poit'],
		this.extras);	
	}
	public RedirectToPOITCO() {
		this.router.navigate(['/poit/preClearenceCo','false'],
			this.extras);
	}
	public RedirectToViewTradeIntimation() {
		this.router.navigate(['/poit/reviewviewtradeintimationscosec'],
			this.extras);
	}
	public RedirectToTradeIntimation() {
		this.router.navigate(['/poit/viewtradeintimations'],
			this.extras);
	}
	public RedirectToUserMaster() {
		this.router.navigate(['/usermanagement'],
			this.extras);
	}

	public RedirectToTradingWindow() {
		this.router.navigate(['/trading-window'],
			this.extras);
	}
	public RedirectToWindowClosure() {
		this.router.navigate(['/trading-window/details'],
			this.extras);
	}
	public RedirectToIssueOfSecurities() {
		this.router.navigate(['/issue-of-security'],
			this.extras);
	}
	public RedirectToIssueOfSecuritiesDetails() {
		this.router.navigate(['/issue-of-security/details'],
			this.extras);
	}
	public RedirectToRuleEngine() {
		this.router.navigate(['/ruleengine'],
			this.extras);
	}

	public RedirectToRuleEngineGrid() {
		this.router.navigate(['/ruleengine/ruleenginegrid/'],
			this.extras);
	}

	public RedirectToCodesAndPolicies() {
		this.router.navigate(['/codesandpolicies'],
			this.extras);
	}

	public RedirectToNotificationConfig() {
		this.router.navigate(['/notification'],
			this.extras);
	}

	public RedirectToNotificationDetails() {
		this.router.navigate(['/notification/detail'],
			this.extras);
	}

	public RedirectToTaskTemplateMain() {
		this.router.navigate(['/tasktemplate/main'],
			this.extras);
	}

	public RedirectToRestrictedList() {
		this.router.navigate(['/restrictedlist'], this.extras);
	}

	public RedirectToTermsAndConditionsManager() {
		this.router.navigate(['/termsandconditionsmanager'], this.extras);
	}

	public RedirectToRestrictionDetails() {
		this.router.navigate(['/restrictedlist/detail'], this.extras);
	}

	public RedirectToFrequentlyAskedQuestions() {
		this.router.navigate(['/frequently-asked-questions'], this.extras);
	}

	public RedirectToTaskTemplate() {
		this.router.navigate(['/tasktemplate/sub-task'],
			this.extras);
	}

	public RedirectToWorkflowMgmt() {
		this.router.navigate(['/workflowmgmt'],
			this.extras);
	}

	public RedirectToWorkflowGrid() {
		this.router.navigate(['/workflowmgmt/workflowgrid/'],
			this.extras);
	}

	public RedirectToGoldenMaster() {
		this.router.navigate(['/goldenmaster'],
			this.extras);
	}
	public RedirectToMeetingMaster() {
		this.router.navigate(['/meetingmaster'],
			this.extras);
	}
	public RedirectToErrorPage() {
		this.router.navigate(['/errorPage'],
			this.extras);
	}
	public RedirectToUserMasterScope() {
		this.router.navigate(['/usermanagement/scope'],
			this.extras);
	}

	public RedirectToDigitalDatabase() {
		this.router.navigate(['/digital-database'],
			this.extras);
	}

	public RedirectToUPSISharing() {
		this.router.navigate(['/upsi-sharing'],
			this.extras);
	}

	public RedirectToUPSISharingAddEditDetails() {
		this.router.navigate(['/upsi-sharing/upsi-sharing-details'],
			this.extras);
	}

	public redirectBack() {
		let queryparams;
		const url = this.url[this.url.length - 1];
		if (url) {
			if (url.split('?')[1]) {
				queryparams = url.split('?')[1].split('&');
			} else {
				queryparams = url.split('?')[1];
			}

			const urls = _.chain(url).split('?').value();

			let pair = null;
			const data = [];

			if (queryparams) {
				queryparams.forEach(function (d) {
					pair = d.split('=');
					data.push({ key: pair[0], value: pair[1] });
				});
				const str = {};
				for (const dat of data) {
					str[dat.key] = dat.value;
				}
				this.router.navigate([urls[0]],
					{ skipLocationChange: true, queryParams: str });
			} else {
				this.router.navigate([urls[0]],
					{ skipLocationChange: true });
			}
		}
	}
	public RedirectToUserManagement() {
		this.router.navigate(['/usermanagement'],
			this.extras);
	}

	public RedirectToClientOnboarding() {
		this.router.navigate(['/client-onboarding'],
			this.extras);
	}

	public RedirectToSignatoryDetails() {
		this.router.navigate(['/client-onboarding/signatory-details/'],
			this.extras);
	}
	public RedirectToDesignatedPerson() {
		this.router.navigate(['/client-onboarding/designated-person-details/'],
			this.extras);
	}
	public RedirectToTradingHolidayList() {
		this.router.navigate(['/holidaylist/holiday-list/'],
			this.extras);
	}
	public RedirectToInsiderDetails() {
		this.router.navigate(['/client-onboarding/insider-details/'],
			this.extras);
	}

	public RedirectToLogOut() {
		this.router.navigate(['/logout'],
			this.extras);
	}

	RedirectToReleaseManagement() {
		this.router.navigate(['/releasemanagement'],
			{ skipLocationChange: true });
	}
	public RedirectToLogsManagement() {
		this.router.navigate(['/logsmanagement'],
			this.extras);
	}
	public RedirectToReportsandFormats() {
		this.router.navigate(['/reports-formats'],
			this.extras);
	}
	
	public RedirectToDynamicReport() {
		this.router.navigate(['/reports-formats/dynamic-reports'],
			this.extras);
	}
	public RedirectToPOITWaiver() {
		this.router.navigate(['/poit/viewWaiverPreClearance'],this.extras);
	}
	public RedirectToPOITCOWaiver() {		
		this.router.navigate(['/poit/preClearenceCo','true'],
			this.extras);
	}
	public RedirectToDematRematSecurities() {
		this.router.navigate(['/poit/viewDematRematSecurities'],
			this.extras);
	}
	public RedirectToRaiseDematRematSecurities() {
		this.router.navigate(['/poit/DematRematSecurities'],
			this.extras);
	}
}
