import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../shared/services/http.service';
import { Observable } from '../../../../node_modules/rxjs';
import { IHTTPResponse } from '../../shared/models/http-response';
import { EnvService } from 'src/app/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {

  private readonly apiMasterBaseUrl =  this.env.baseUrl
    + environment.services.notification.baseUrl;

    private readonly userBaseUrl =  this.env.baseUrl
    + environment.services.kmp.baseUrl;

  constructor(private httpService: HttpService, private env: EnvService) { }

  getWebNotification(data): Observable<any> {
    const url = environment.services.notification.api.getNotification;
    return this.httpService.post<IHTTPResponse>(this.apiMasterBaseUrl, url, data);
  }

  setThemePreference(data): Observable<any> {
    const url = environment.services.kmp.api.UpdateDarkMode;
    return this.httpService.post<IHTTPResponse>(this.userBaseUrl, url, data);
  }

  updateWebNotification(data): Observable<any> {
    const url = environment.services.notification.api.updateNotification;
    return this.httpService.post<IHTTPResponse>(this.apiMasterBaseUrl, url, data);
  }
}
