import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, ValidationErrors, FormControl, Validators } from '@angular/forms';

@Directive({
  selector: '[NumericValidator][ngModel][min][max]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NumericValidatorDirective,
      multi: true
    }
  ]
})
export class NumericValidatorDirective implements Validator {

  validator: ValidatorFn;
  ctrlName: string;
  @Input() min: string;
  @Input() max: string;

  constructor() { }
  validate(control: AbstractControl): ValidationErrors {
    const ctrl = new FormControl(control.value, Validators.compose([Validators.pattern('^[0-9]*$'),
    Validators.min(parseInt(this.min, 10)), Validators.max(parseInt(this.max, 10))]));
    if (control.value === null || control.value === undefined) {
      control.setValue('');
    }
    if (ctrl.hasError('max')) {
      return {
        max: {
          valid: false
        }
      }
    } else if (ctrl.hasError('min')) {
      return {
        min: {
          valid: false
        }
      }
    } else if (ctrl.hasError('pattern')) {
      return {
        NumericValidator: {
          valid: false
        }
      }
    } else {
      return null;
    }
    return null;
  }
}
