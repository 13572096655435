import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvService } from 'src/app/services/env.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpService,
    private env: EnvService
  ) { }

  private readonly apiprojectUrl = this.env.baseUrl + environment.services.cosecbase.baseUrl;

  getNotificationDetails(data) {
    const url = environment.services.cosecbase.api.getNotification;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

  updateNotificationDetails(data) {
    const url = environment.services.cosecbase.api.addUpdateNotification;
    return this.http.post<any>(this.apiprojectUrl, url, data);
  }

}
