import { Component, OnInit, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { RouterService } from '../../../../../shared/services/router.service';
import { DataService } from '../../../../../shared/services/data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// lodash
import * as _ from 'lodash';
import { OnboardingDetailsService } from '../../../client-onboarding/service/onboarding-details.service';
import { ToastMessageService } from 'src/app/shared/services';
import { IPoitvariables } from 'src/app/shared/models/poitvariables';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import { takeWhile } from 'rxjs/operators';
import { ModulesEnum } from 'src/app/shared/constants/enum';

@Component({
  selector: 'app-doormatpopupcosecddassignee',
  templateUrl: './doormatpopupcosecddassignee.component.html',
  styleUrls: ['./doormatpopupcosecddassignee.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DoormatPopupCoSecUPSISPOCAssigneeComponent implements OnInit, OnDestroy {

  // @Output() selectedDialogAction = new EventEmitter<any>();
  issueOfSecurityRequired: any;
  clientId: number;
  formDRequired: boolean;
  poit: boolean;
  sdd: boolean;
  public loading: boolean;

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private dataService: DataService,
    private encryptDecryptService: EncryptDecryptService,
    private onboardingService: OnboardingDetailsService,
    private toastMessageService: ToastMessageService
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.sessionStorageService.currentAssignmentDetails.pipe(takeWhile(() => true)).subscribe((result) => {
      this.loading = false;
      if (result) {
        this.clientId = result.ClientId;
        this.getPIOTVariables();
        this.checkModules();
          }
    });
  }

  ngOnDestroy(): void {
  }

  getPIOTVariables() {
    this.loading = true;
    const piotVariables: IPoitvariables = {} as IPoitvariables;
    piotVariables.ClientId = this.clientId;
    this.onboardingService.getPOITVariables(piotVariables).subscribe((result: any) => {
      if (result) {
        this.issueOfSecurityRequired = result.IssueOfSecurityRequired;
      }
      this.loading = false;
    },
      error => {
        //  error
        if (error.error !== undefined && error.error.StatusCode !== 502) {
          this.toastMessageService.showInfoMessage('Something went wrong. Please contact administrator');
        }
        this.loading = false;
      }
    );
  }

  checkModules() {
    this.sessionStorageService.companyModuleList.subscribe((result) => {
      if (result) {
        const decryptedModules: any = this.encryptDecryptService.decrypt(result);
        decryptedModules.map((item) => {
          if (item === ModulesEnum.POIT) {
            this.poit = true;
          }
          if (item === ModulesEnum.SDD) {
            this.sdd = true;
          }
        });

      }
    });
  }

  callClosePopup() {
    this.dataService.closeMenuBox();
  }

  callPOIT() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToPOIT();
  }

  callUPSISharing() {
    this.dataService.closeMenuBox();
    this.routerService.RedirectToUPSISharing();
  }

  // callTradingPlan() {

  // }
}
