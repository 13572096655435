import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { RouterService } from '../../../shared/services/router.service';
import { ToastMessageService } from '../../../shared/services/toast-message.service';
import { DataService } from '../../../shared/services/data.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import 'primeng/resources/primeng.min.css';
import { NavMenuService } from '../../services/nav-menu.service';
import { IUserAssignment } from '../../../shared/models/userassignment';
import * as _ from 'lodash';
// import { debug } from 'util';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../services/notification.service';
import { RoleEnum } from 'src/app/shared/constants/enum';
import { Console } from 'console';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {

  // private userDetailsSubscription: Subscription;
  // private dataDetailsSubscription: Subscription;
  // private offeringSubscription: Subscription;
  // private currentAssignmentSubscription: Subscription;
  public progressBar = false;
  subscriptions: Subscription[] = [];
  private isSubscriptionAlive = false;
  public isLoggedIn: boolean;
  public companyList: any[] = [];
  dasboardUser = true;
  hightlightVal: string;
  showNotificationDialog: boolean;
  currentUserDetails: any;
  notificationCount: number;
  notificationDetails: any;
  unreadNotifications: any[];
  offeringName: string;
  currentAssignment: IUserAssignment;
  currentRole: string;
  companyName: string;
  showDoorMat = false;
  selectedDialogAction: any;
  isInstance: boolean;
  displayNotification: boolean;
  switchRole: boolean;
  selectedCom: any;
  public loading = false;
  public theameMode: any;
  public theams: any;
  public manageDropDown: boolean;
  constructor(private routerService: RouterService,
    private sessionStorage: SessionStorageService,
    private dataService: DataService,
    router: Router,
    private navmenuService: NavMenuService,
    private toastMessageService: ToastMessageService,
    private notificationService: NotificationService) {
    const _this = this;
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
      }
    });
    this.theams = [
      { label: 'Dark', value: 'Dark' },
      { label: 'Light', value: 'Light' },
    ];
    this.theameMode = 'Light';
    this.sessionStorage.currentAssignmentDetails.subscribe((data: any) => {
      if (data) {
        this.selectedCom = data;
      }
    });
    this.displayNotification = false;
  }


  ngOnInit() {
    this.manageDropDown = false;
    this.switchRole = false;
    this.showNotificationDialog = false;
    this.notificationCount = 0;
    this.unreadNotifications = [];
    this.isInstance = environment.instance;
    this.sessionStorage.currentCompany.subscribe(x => {
      //console.log(x);
      if (x === 'All') {
        this.companyName = '';
      } else {
        this.companyName = x;
      }
    });

    this.subscriptions.push(this.dataService.menuClicked.subscribe((action) => {
      this.closeDialog();
    }));

    this.subscriptions.push(this.dataService.switchRole.subscribe((action) => {
      if (action) {
        this.switchRole = true;
      }
    }));
    this.isSubscriptionAlive = true;

    this.sessionStorage.userDetails.pipe(takeWhile(() => this.isSubscriptionAlive)).subscribe((result) => {
      if (result) {
        this.currentUserDetails = result;
        if (this.currentUserDetails.DarkMode) {
          this.theameMode = 'Dark';
          this.setDarkMode();
        } else {
          this.theameMode = 'Light';
          this.setLightMode();
        }
        this.populateNotifications();
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });

    this.sessionStorage.offeringName.pipe(takeWhile(() => this.isSubscriptionAlive)).subscribe((result) => {
      if (result) {
        this.offeringName = result;
      } else {
        this.offeringName = '';
      }
    });

    this.sessionStorage.currentAssignmentDetails.pipe(takeWhile(() => this.isSubscriptionAlive)).subscribe((result) => {
      if (result) {
        this.currentAssignment = result;
        if (this.currentAssignment.RoleName === 'Admin') {
          if (JSON.parse(this.currentAssignment.OwnershipTags).Company.length === 1 &&
            JSON.parse(this.currentAssignment.OwnershipTags).Company[0] === 'All') {
            this.currentRole = 'Super Admin';
            this.companyList = this.currentAssignment.OwnershipTags.Company;
            // this.companyList.shift();
          } else if (JSON.parse(this.currentAssignment.OwnershipTags).Company[0] === 'EY') {
            this.currentRole = 'EY Admin';
            JSON.parse(this.currentAssignment.OwnershipTags).Company.forEach(v => {
              if (v !== 'EY') {
                this.companyList.push({ label: v, value: v });
              }
            });
            // this.companyName = this.companyList[0].value;
            // this.sessionStorage.setCurrentCompany(this.companyName);
          } else {
            this.currentRole = 'Client Admin';
            JSON.parse(this.currentAssignment.OwnershipTags).Company.forEach(v => {
              if (v !== 'EY') {
                this.companyList.push({ label: v, value: v });
              }
            });
            // this.companyName = this.companyList[0].value;
            // this.sessionStorage.setCurrentCompany(this.companyName);
          }
        } else {
          this.currentRole = this.currentAssignment.RoleName;
          JSON.parse(this.currentAssignment.OwnershipTags).Company.forEach(v => {
            if (v !== 'EY') {
              this.companyList.push({ label: v, value: v });
            }
          });
          // this.companyName = this.companyList[0].value;
          // this.sessionStorage.setCurrentCompany(this.companyName);
        }
      } else {
        this.currentRole = '';
        this.currentAssignment = null;
        this.companyName = '';
      }
    });

    this.getNotifications();
    interval(2000 * 60).subscribe(x => {
      this.getNotifications();
    });
  }

  getNotifications() {
    try{
    this.notificationService.getNotificationDetails({ ClientId: this.currentAssignment.ClientId, UserEmail: this.currentUserDetails.Email }).subscribe(data => {
      const notSeenNotification = [];
      const allNotifications = [];
      if (data) {
        data.forEach(element => {
          if (element.MailTo && element.MailTo.Bell.length) {
            for (let item = 0; item < element.MailTo.Bell.length; item++) {
              if (element.MailTo.Bell[item].RoleName && element.MailTo.Bell[item].RoleName === this.currentAssignment.RoleName) {
                if (element.IsRead.SeenBy.length === 0 || !element.IsRead.SeenBy.includes(this.currentUserDetails.Email)) {
                  notSeenNotification.push(element);
                }
                allNotifications.push(element);
                break;
              }
            }
          }
        });
      }
      this.notificationDetails = allNotifications;
      this.notificationCount = notSeenNotification.length;
    });
  }catch(err){
    
  }
  }

  // changeClient() {
  //   this.sessionStorage.setCurrentCompany(this.companyName);
  // }

  showNotifications() {
    this.showNotificationDialog = true;
    if (this.unreadNotifications.length > 0) {
      this.navmenuService.updateWebNotification(this.unreadNotifications).subscribe();
    }
  }
  hideNotifications() {
    this.showNotificationDialog = false;
    this.populateNotifications();
  }
  populateNotifications() {
    const inputData = {
      Email: this.currentUserDetails.Email,
      IsRead: false
    };
  }

  ngOnDestroy() {
  }

  manageDoormat() {
    if (this.showDoorMat) {
      this.showDoorMat = false;
    }
    if (this.displayNotification) {
      this.displayNotification = false;
    }
    setTimeout(() => {
      this.manageDropDown = false;
    }, 1000);
  }

  showDoorMatMenu(event) {
    this.showDoorMat = true;
    this.displayNotification = false;
    this.manageDropDown = true;
    setTimeout(() => {
      this.manageDropDown = false;
    }, 1000);
    event.stopPropagation();
  }

  closeDialog() {
    this.showDoorMat = false;
  }
  redirectToNotification(event) {
    if (this.displayNotification) {
      this.manageDropDown = false;
      this.displayNotification = false;
    } else {
      this.manageDropDown = true;
      this.displayNotification = true;
      this.showDoorMat = false;
    }
    event.stopPropagation();
  }
  notificationEvent(event) {
    this.displayNotification = false;
    this.notificationCount = 0;
  }
  // this.routerService.RedirectToNotificationConfig();
  // this.toastMessageService.showInfoMessage('Coming soon...');
  gotodashboard() {
    this.dataService.updateDesignatedPersonData(undefined);
    setTimeout(() => {
      this.redirectToDashboard(this.selectedCom);
    }, 100);
    this.manageDropDown = true;
  }
  private redirectToDashboard(assignment: IUserAssignment) {
    // assignment.OwnershipTags = JSON.parse(assignment.OwnershipTags);
    if (assignment.RoleName === RoleEnum.Admin && (assignment.OwnershipTags === null)) {
      this.routerService.RedirectToMasterAdminDashboard();
    } else {
      this.CoSecRedirection(assignment);
    }
  }

  private CoSecRedirection(assignment: IUserAssignment) {
    // if (assignment.RoleName === RoleEnum.Admin) {
    this.routerService.RedirectToCoSecAdminDashboard();
    // }
  }
  public setDarkMode() {
    document.documentElement.style.setProperty('--container-color', '#747480');
    document.documentElement.style.setProperty('--white-color', '#2e2e38');
    document.documentElement.style.setProperty('--subtitle-color', '#ffffff');
    document.documentElement.style.setProperty('--black-color', '#ffffff');
    document.documentElement.style.setProperty('--dropdown-label', '#ffffff');
    document.documentElement.style.setProperty('--dark-black-color', '#ffffff');
    document.documentElement.style.setProperty('--disable-color', '#747480');
    document.documentElement.style.setProperty('--border-color', '#747480');
    document.documentElement.style.setProperty('--grid-seperator-color', '#747480');
    document.documentElement.style.setProperty('--tab-bg', '#c4c4cc');
    document.documentElement.style.setProperty('--white-gray-color', '#a9a9a9');
    document.documentElement.style.setProperty('--gray-calendor', '#ffffff');
    document.documentElement.style.setProperty('--blue-calendor', '#ffffff');
    document.documentElement.style.setProperty('--gray-content-color', '#747480');
    document.documentElement.style.setProperty('--gray-black-bg', '#2e2e38');
    document.documentElement.style.setProperty('--gray-color', '#747480');
    document.documentElement.style.setProperty('--gray-black-color', '#dbdbd');
    document.documentElement.style.setProperty('--table-box-shadow', '#ffffffa6');
    document.documentElement.style.setProperty('--table-border-color', '#dddddd');
    document.documentElement.style.setProperty('--green-color', '#57e188');
    document.documentElement.style.setProperty('--scrollbar-color', '#c4c4cc');
    document.documentElement.style.setProperty('--gray-seprator-color', '#747480');
    document.documentElement.style.setProperty('--editor-header-color', '#747480');
    document.documentElement.style.setProperty('--gray-white-border', '#bdbdbd');
    document.documentElement.style.setProperty('--red-light-bg', '#fe9b90');
    document.documentElement.style.setProperty('--red-color-text', '#ff736b');
    document.documentElement.style.setProperty('--red-calendor', '#ff736b');
    document.documentElement.style.setProperty('--table-blue-border', '#4ebfea');
    document.documentElement.style.setProperty('--table-green-border', '#57e188');
    document.documentElement.style.setProperty('--table-blue', '#c2e9f800');
    document.documentElement.style.setProperty('--table-light-green', '#c2e9f800');
    document.documentElement.style.setProperty('--link-title', '#4ebfea');
    document.documentElement.style.setProperty('--yellow-bg', '#ffe600');
    document.documentElement.style.setProperty('--light-green', '#8be8ad');
    document.documentElement.style.setProperty('--btn-red', '#fe9b90');
    document.documentElement.style.setProperty('--light-pink', '#feb46a');
    document.documentElement.style.setProperty('--light-purple', '#a081e4');
    document.documentElement.style.setProperty('--dropdown-seprator-color', '#d4d4d4');
    document.documentElement.style.setProperty('--white-gray-btn', '#c4c4cc');
    document.documentElement.style.setProperty('--gray-scrollbar-color', '#969696');
    document.documentElement.style.setProperty('--action-bg-color', '#9797a5');
    document.documentElement.style.setProperty('--yellow-seperator-color', '#747480');
    document.documentElement.style.setProperty('--black-seperator-color', '#747480');

    document.documentElement.style.setProperty('--eylogo-path', 'url(./assets/Dark/EY_LOGO_darkmode.svg)');
    document.documentElement.style.setProperty('--home-path', 'url(./assets/Dark/Topbar_home.svg)');
    document.documentElement.style.setProperty('--notification-path', 'url(./assets/Dark/Topbar_notification_icon.svg)');
    document.documentElement.style.setProperty('--sucessIcon-path', 'url(./assets/Dark/darkmode_success.svg)');
    document.documentElement.style.setProperty('--tickicon-path', 'url(./assets/Dark/small_green_tick.svg)');
    document.documentElement.style.setProperty('--edit-path', 'url(./assets/Dark/darkmode_edit.svg)');
    document.documentElement.style.setProperty('--comment-path', 'url(./assets/Dark/darkmode_comment.svg)');
    document.documentElement.style.setProperty('--save-path', 'url(./assets/Dark/darkmode_save.svg)');
    document.documentElement.style.setProperty('--delete-path', 'url(./assets/Dark/darkmode_delete.svg)');
    document.documentElement.style.setProperty('--cancel-path', 'url(./assets/Dark/Dark-cancel.svg)');
    document.documentElement.style.setProperty('--show-path', 'url(./assets/Dark/view_darkmode.svg)');
    document.documentElement.style.setProperty('--add-path', 'url(./assets/Dark/plus.png)');
    document.documentElement.style.setProperty('--Download-path', 'url(./assets/Dark/darkmode_download.svg)');
    document.documentElement.style.setProperty('--update-path', 'url(./assets/Dark/Topbar_home.svg)');
    document.documentElement.style.setProperty('--minus-path', 'url(./assets/Dark/minus.svg)');
    document.documentElement.style.setProperty('--audit-trail-path', 'url(./assets/Dark/darkmode_audit_trail.svg)');
    document.documentElement.style.setProperty('--user-path', 'url(./assets/Dark/darkmode_user.svg)');
    document.documentElement.style.setProperty('--up-path', 'url(./assets/Dark/up_arrow_darkmode.svg)');
    document.documentElement.style.setProperty('--attach-path', 'url(./assets/Dark/darkmode_attach_icon.svg)');
    document.documentElement.style.setProperty('--down-path', 'url(./assets/Dark/down_arrow_darkmode.svg)');
    document.documentElement.style.setProperty('--calendor-path', 'url(./assets/Dark/Dark-date_range.svg)');
    document.documentElement.style.setProperty('--page-path', 'url(./assets/Dark/page.svg)');
    document.documentElement.style.setProperty('--bar-path', 'url(./assets/Dark/dark-leaderboard.svg)');
    document.documentElement.style.setProperty('--close-path', 'url(./assets/Dark/close-popup.svg)');
    document.documentElement.style.setProperty('--upload-path', 'url(./assets/Dark/DarkModepublish-24.svg)');
    document.documentElement.style.setProperty('--help-path', 'url(./assets/Dark/darkmode_info_icon.svg)');
    document.documentElement.style.setProperty('--description-path', 'url(./assets/Dark/Dark-mode-description.svg)');
    document.documentElement.style.setProperty('--attention-path', 'url(./assets/Dark/darkmode_caution.svg)');
    document.documentElement.style.setProperty('--down-arrow-path', 'url(./assets/Dark/darkmode_down.svg)');
    document.documentElement.style.setProperty('--darkmode_off-path', 'url(./assets/Dark/darkmode_on.svg)');
    document.documentElement.style.setProperty('--daymode_on-path', 'url(./assets/Dark/daymode_off.svg)');
    document.documentElement.style.setProperty('--calendor1-path', 'url(./assets/Dark/darkmode_calendar.svg)');
    document.documentElement.style.setProperty('--accelerate-path', 'url(./assets/Dark/Topbar__middle_graphic.svg)');
    document.documentElement.style.setProperty('--separator-path', 'url(./assets/Dark/Topbar_separators.svg)');
    document.documentElement.style.setProperty('--menu-icon', 'url(./assets/Dark/menu-darkmode.svg)');
    document.documentElement.style.setProperty('--lock-path', 'url(./assets/Dark/dark-lock.svg)');
    document.documentElement.style.setProperty('--document-path', 'url(./assets/Dark/darkmode_search.svg)');

  }
  public setLightMode() {
    document.documentElement.style.setProperty('--container-color', '#ebebeb');
    document.documentElement.style.setProperty('--white-color', '#ffffff');
    document.documentElement.style.setProperty('--subtitle-color', '#333333');
    document.documentElement.style.setProperty('--black-color', '#2e2e38');
    document.documentElement.style.setProperty('--dropdown-label', '#4a4a4a');
    document.documentElement.style.setProperty('--dark-black-color', '#000000');
    document.documentElement.style.setProperty('--disable-color', '#f7f7f7');
    document.documentElement.style.setProperty('--border-color', '#e6e6e6');
    document.documentElement.style.setProperty('--grid-seperator-color', '#e6e6e6');
    document.documentElement.style.setProperty('--tab-bg', '#f5f5fa');
    document.documentElement.style.setProperty('--white-gray-color', '#ffffff');
    document.documentElement.style.setProperty('--gray-calendor', '#6d6f71');
    document.documentElement.style.setProperty('--blue-calendor', '#1569b3');
    document.documentElement.style.setProperty('--gray-content-color', '#f4f4f4');
    document.documentElement.style.setProperty('--gray-black-bg', '#f4f4f4');
    document.documentElement.style.setProperty('--gray-color', '#bdbdbd');
    document.documentElement.style.setProperty('--gray-black-color', '#bdbdbd');
    document.documentElement.style.setProperty('--table-box-shadow', '#0e0d0e29');
    document.documentElement.style.setProperty('--table-border-color', '#dbdbdb');
    document.documentElement.style.setProperty('--green-color', '#3cbf59');
    document.documentElement.style.setProperty('--scrollbar-color', '#4c4c4c');
    document.documentElement.style.setProperty('--gray-seprator-color', '#d8d8d8');
    document.documentElement.style.setProperty('--editor-header-color', '#e5e5e5');
    document.documentElement.style.setProperty('--gray-white-border', '#e6e6e6');
    document.documentElement.style.setProperty('--red-light-bg', '#fee6e3');
    document.documentElement.style.setProperty('--red-color-text', '#b9241c');
    document.documentElement.style.setProperty('--red-calendor', '#ef4156');
    document.documentElement.style.setProperty('--table-blue-border', '#c2e9f8');
    document.documentElement.style.setProperty('--table-green-border', '#e2f9ea');
    document.documentElement.style.setProperty('--table-blue', '#c2e9f8');
    document.documentElement.style.setProperty('--table-light-green', '#e2f9ea');
    document.documentElement.style.setProperty('--link-title', '#198ce5');
    document.documentElement.style.setProperty('--$yellow-bg', '#fff8b9');
    document.documentElement.style.setProperty('--light-green', '#e2f9ea');
    document.documentElement.style.setProperty('--btn-red', '#fe9b90');
    document.documentElement.style.setProperty('--light-pink', '#f4dcd9');
    document.documentElement.style.setProperty('--light-purple', '#ded2f8');
    document.documentElement.style.setProperty('--dropdown-seprator-color', '#d8d8d8');
    document.documentElement.style.setProperty('--white-gray-btn', '#ffffff');
    document.documentElement.style.setProperty('--gray-scrollbar-color', '#d8d8d8');
    document.documentElement.style.setProperty('--action-bg-color', '#f4f4f4');
    document.documentElement.style.setProperty('--yellow-seperator-color', '#FFE600');
    document.documentElement.style.setProperty('--black-seperator-color', '#2e2e38');

    document.documentElement.style.setProperty('--eylogo-path', 'url(./assets/Light/EY_LOGO_lightmode.svg)');
    document.documentElement.style.setProperty('--home-path', 'url(./assets/Light/home.svg)');
    document.documentElement.style.setProperty('--notification-path', 'url(./assets/Light/notification_icon.svg)');
    document.documentElement.style.setProperty('--sucessIcon-path', 'url(./assets/Light/green_tick.svg)');
    document.documentElement.style.setProperty('--tickicon-path', 'url(./assets/Light/small_green_tick.svg)');
    document.documentElement.style.setProperty('--edit-path', 'url(./assets/Light/edit.svg)');
    document.documentElement.style.setProperty('--comment-path', 'url(./assets/Light/comment-24px.svg)');
    document.documentElement.style.setProperty('--save-path', 'url(./assets/Light/save.svg)');
    document.documentElement.style.setProperty('--delete-path', 'url(./assets/Light/delete.svg)');
    document.documentElement.style.setProperty('--cancel-path', 'url(./assets/Light/cancel.svg)');
    document.documentElement.style.setProperty('--show-path', 'url(./assets/Light/visibility-24px.svg)');
    document.documentElement.style.setProperty('--add-path', 'url(./assets/Light/plus.png)');
    document.documentElement.style.setProperty('--Download-path', 'url(./assets/Light/download.svg)');
    document.documentElement.style.setProperty('--update-path', 'url(./assets/Light/update.png)');
    document.documentElement.style.setProperty('--minus-path', 'url(./assets/Light/minus.svg)');
    document.documentElement.style.setProperty('--audit-trail-path', 'url(./assets/Light/audit_trail.svg)');
    document.documentElement.style.setProperty('--user-path', 'url(./assets/Light/perm_identity-24px.svg)');
    document.documentElement.style.setProperty('--up-path', 'url(./assets/Light/up_arrow.svg)');
    document.documentElement.style.setProperty('--attach-path', 'url(./assets/Light/attach_icon.svg)');
    document.documentElement.style.setProperty('--down-path', 'url(./assets/Light/down_arrow.svg)');
    document.documentElement.style.setProperty('--calendor-path', 'url(./assets/Light/date_range-24px.svg)');
    document.documentElement.style.setProperty('--page-path', 'url(./assets/Light/wysiwyg-24px.svg)');
    document.documentElement.style.setProperty('--bar-path', 'url(./assets/Light/leaderboard-24px.svg)');
    document.documentElement.style.setProperty('--close-path', 'url(./assets/Light/close-popup.svg)');
    document.documentElement.style.setProperty('--upload-path', 'url(./assets/Light/publish-24px.svg)');
    document.documentElement.style.setProperty('--help-path', 'url(./assets/Light/darkmode_info_icon.svg)');
    document.documentElement.style.setProperty('--description-path', 'url(./assets/Light/description-24px.svg)');
    document.documentElement.style.setProperty('--attention-path', 'url(./assets/Light/attention.svg)');
    document.documentElement.style.setProperty('--down-arrow-path', 'url(./assets/Light/downarrow-body.svg)');
    document.documentElement.style.setProperty('--darkmode_off-path', 'url(./assets/Light/darkmode_off.svg)');
    document.documentElement.style.setProperty('--daymode_on-path', 'url(./assets/Light/daymode_on.svg)');
    document.documentElement.style.setProperty('--calendor1-path', 'url(./assets/Light/calendor_1.svg)');
    document.documentElement.style.setProperty('--accelerate-path', 'url(./assets/Light/accelerate.svg)');
    document.documentElement.style.setProperty('--separator-path', 'url(./assets/Light/separator.svg)');
    document.documentElement.style.setProperty('--menu-icon', 'url(./assets/Light/menu-lightmode.svg)');
    document.documentElement.style.setProperty('--lock-path', 'url(./assets/Light/lock.svg)');
    document.documentElement.style.setProperty('--document-path', 'url(./assets/Light/search.svg)');

  }

  public changeTheme() {
    if (this.theameMode === 'Dark') {
      const data = {
        Id: this.currentUserDetails.Id,
        DarkMode: true
      };
      this.loading = true;
      this.navmenuService.setThemePreference(data).subscribe(() => {
        this.setDarkMode();
        this.loading = false;
        this.currentUserDetails.DarkMode = true;
        this.sessionStorage.setUserDetails(this.currentUserDetails);
      }, () => {
        this.loading = false;
      });
    } else {
      const data = {
        Id: this.currentUserDetails.Id,
        DarkMode: false
      };
      this.loading = true;
      this.navmenuService.setThemePreference(data).subscribe(() => {
        this.setLightMode();
        this.loading = false;
        this.currentUserDetails.DarkMode = false;
        this.sessionStorage.setUserDetails(this.currentUserDetails);
      }, () => {
        this.loading = false;
      });
    }
      this.manageDropDown = true;
  }
}
