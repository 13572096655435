import { Injectable } from '@angular/core';
import { ApiResponseCode } from '../constants/api-response-code';
import { ToastMessageService } from './toast-message.service';
// import { Observable, BehaviorSubject } from '../../../../node_modules/rxjs';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
})
export class MasterDataService {
    noFilterData = {};
    constructor(
        private toastMessageService: ToastMessageService,
        private dataService: DataService

    ) { }

    public handleError(error: any) {
        if (error.StatusCode === ApiResponseCode.error) {
            this.toastMessageService.showErrorMessage(error.Message);
        } else if (error.StatusCode === ApiResponseCode.serverError) {
            // this.toastMessageService.showErrorMessage(error.Message);
            this.toastMessageService.showErrorMessage('Something went wrong. Please contact administrator.');
        } else {
            this.toastMessageService.showErrorMessage('Something went wrong. Please contact administrator.');
        }
    }


}
