import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class ProgressBarService {
  public progressBar = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  toggle(progressBar: boolean) {
    this.progressBar = progressBar;
    this.change.emit(this.progressBar);
  }
}
