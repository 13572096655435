import { IGoldenMaster } from './../models/goldenmaster';
import { Injectable } from '@angular/core';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { IUser } from '../models/user';
import { IUserAssignment } from '../models/userassignment';
import { IClient } from '../models/client';

@Injectable({
	providedIn: 'root',
  })
export class LocalStorageService {
	private readonly _authToken: string = this.encryptDecryptService.encrypt('auth_token');
	private readonly _userDetails: string = this.encryptDecryptService.encrypt('user_details');

	private readonly _moduleList: string = this.encryptDecryptService.encrypt('module_list');
	private readonly _countryListDetails: string = this.encryptDecryptService.encrypt('country_details');
	private readonly _companyTypes: string = this.encryptDecryptService.encrypt('company_types');
	private readonly _companyEssentials: string = this.encryptDecryptService.encrypt('company_essentials');
	private readonly _meetingEssentials: string = this.encryptDecryptService.encrypt('meeting_essentials');
	private readonly _coSecTransactions: string = this.encryptDecryptService.encrypt('ecg_transactions');
	private readonly _fieldsInventory: string = this.encryptDecryptService.encrypt('fields_inventory');

	private readonly _clientMasterList: string = this.encryptDecryptService.encrypt('client_master_details');
	private readonly _userAssignments: string = this.encryptDecryptService.encrypt('userassignment_role_tenant');
	private readonly _screenName: string = this.encryptDecryptService.encrypt('screen_name');
	private readonly _offeringName: string = this.encryptDecryptService.encrypt('offering_name');
	private readonly _currentAssignment: string = this.encryptDecryptService.encrypt('current_assignment');
	private readonly _currentCompany: string = this.encryptDecryptService.encrypt('current_company');
	private readonly _companyModuleList: string = this.encryptDecryptService.encrypt('company_module_list');
	private readonly _dateFormat: string = this.encryptDecryptService.encrypt('date_format');

	constructor(private encryptDecryptService: EncryptDecryptService) { }

	get authToken(): string {
		if (localStorage.getItem(this._authToken) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._authToken));
		}
		return null;
	}
	set authToken(value: string) {
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._authToken, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._authToken) !== null) {
				localStorage.removeItem(this._authToken);
			}
		}
	}

	get userDetails(): IUser {
		if (localStorage.getItem(this._userDetails) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._userDetails));
		}
		return null;
	}

	set userDetails(value: IUser) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._userDetails, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._userDetails) !== null) {
				localStorage.removeItem(this._userDetails);
			}
		}
	}

	// Client List
	get clientMasterList(): IClient {
		if (localStorage.getItem(this._clientMasterList) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._clientMasterList));
		}
		return null;
	}
	set clientMasterList(value: IClient) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._clientMasterList, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._clientMasterList) !== null) {
				localStorage.removeItem(this._clientMasterList);
			}
		}
	}

	// golden master list details
	get moduleList(): Array<IGoldenMaster> {
		if (localStorage.getItem(this._moduleList) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._moduleList));
		}
		return null;
	}
	set moduleList(value: Array<IGoldenMaster>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._moduleList, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._moduleList) !== null) {
				localStorage.removeItem(this._moduleList);
			}
		}
	}

	get companyTypes(): Array<IGoldenMaster> {
		if (localStorage.getItem(this._companyTypes) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._companyTypes));
		}
		return null;
	}
	set companyTypes(value: Array<IGoldenMaster>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._companyTypes, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._companyTypes) !== null) {
				localStorage.removeItem(this._companyTypes);
			}
		}
	}

	get companyEssentials(): Array<IGoldenMaster> {
		if (localStorage.getItem(this._companyEssentials) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._companyEssentials));
		}
		return null;
	}
	set companyEssentials(value: Array<IGoldenMaster>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._companyEssentials, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._companyEssentials) !== null) {
				localStorage.removeItem(this._companyEssentials);
			}
		}
	}

	get meetingEssentials(): Array<IGoldenMaster> {
		if (localStorage.getItem(this._meetingEssentials) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._meetingEssentials));
		}
		return null;
	}
	set meetingEssentials(value: Array<IGoldenMaster>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._meetingEssentials, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._meetingEssentials) !== null) {
				localStorage.removeItem(this._meetingEssentials);
			}
		}
	}

	get coSecTransactions(): Array<IGoldenMaster> {
		if (localStorage.getItem(this._coSecTransactions) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._coSecTransactions));
		}
		return null;
	}
	set coSecTransactions(value: Array<IGoldenMaster>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._coSecTransactions, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._coSecTransactions) !== null) {
				localStorage.removeItem(this._coSecTransactions);
			}
		}
	}

	get fieldsInventory(): Array<IGoldenMaster> {
		if (localStorage.getItem(this._fieldsInventory) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._fieldsInventory));
		}
		return null;
	}
	set fieldsInventory(value: Array<IGoldenMaster>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._fieldsInventory, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._fieldsInventory) !== null) {
				localStorage.removeItem(this._fieldsInventory);
			}
		}
	}

  // Country list details
	get countryListDetails(): Array<IGoldenMaster> {
		if (localStorage.getItem(this._countryListDetails) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._countryListDetails));
		}
		return null;
	}
	set countryListDetails(value: Array<IGoldenMaster>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._countryListDetails, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._countryListDetails) !== null) {
				localStorage.removeItem(this._countryListDetails);
			}
		}
	}

	get userAssignmentsDetails(): Array<IUserAssignment> {
		if (localStorage.getItem(this._userAssignments) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._userAssignments));
		}
		return null;
	}
	set userAssignmentsDetails(value: Array<IUserAssignment>) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._userAssignments, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._userAssignments) !== null) {
				localStorage.removeItem(this._userAssignments);
			}
		}
	}

	get currentAssignmentDetails(): IUserAssignment {
		if (localStorage.getItem(this._currentAssignment) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._currentAssignment));
		}
		return null;
	}
	set currentAssignmentDetails(value: IUserAssignment) {
		if (value !== null && value !== undefined) {
			localStorage.setItem(this._currentAssignment, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._currentAssignment) !== null) {
				localStorage.removeItem(this._currentAssignment);
			}
		}
	}

	get screenType(): string {
		if (localStorage.getItem(this._screenName) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._screenName));
		}
		return null;
	}
	set screenType(value: string) {
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._screenName, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._screenName) !== null) {
				localStorage.removeItem(this._screenName);
			}
		}
	}

	get offeringName(): string {
		if (localStorage.getItem(this._offeringName) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._offeringName));
		}
		return null;
	}
	set offeringName(value: string) {
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._offeringName, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._offeringName) !== null) {
				localStorage.removeItem(this._offeringName);
			}
		}
	}

	get currentCompany(): string {
		if (localStorage.getItem(this._currentCompany) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._currentCompany));
		}
		return null;
	}
	set currentCompany(value: string) {
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._currentCompany, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._currentCompany) !== null) {
				localStorage.removeItem(this._currentCompany);
			}
		}
	}

	get companyModuleList(): string {
		if (localStorage.getItem(this._companyModuleList) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._companyModuleList));
		}
		return null;
	}
	set companyModuleList(value: string) {
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._companyModuleList, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._companyModuleList) !== null) {
				localStorage.removeItem(this._companyModuleList);
			}
		}
	}

	get dateFormat(): string {
		if (localStorage.getItem(this._dateFormat) !== null) {
			return this.encryptDecryptService.decrypt(localStorage.getItem(this._dateFormat));
		}
		return null;
	}
	set dateFormat(value: string) {
		if (value !== null && value !== '' && value !== undefined) {
			localStorage.setItem(this._dateFormat, this.encryptDecryptService.encrypt(value));
		} else {
			if (localStorage.getItem(this._dateFormat) !== null) {
				localStorage.removeItem(this._dateFormat);
			}
		}
	}

}
