import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { RouterService } from 'src/app/shared/services/router.service';
import { ToastMessageService } from '../../../shared/services/toast-message.service';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  public progressBar = false;
  loggedOut:boolean=false;
  constructor(private sessionStorage: SessionStorageService,private localStorageService: LocalStorageService,private routerService: RouterService,private loginService: LoginService,private toastMessageService: ToastMessageService) { }

  ngOnInit(): void {    
    const data={
      Email: this.localStorageService.userDetails.Email,
    };
    this.progressBar = true;
    this.loginService.logout(data).pipe().subscribe((result) => {    
      this.progressBar = false; 
      setTimeout(()=>{
        this.loggedOut=result; 
      },3000)           
      if (result != null) {
        this.sessionStorage.flushOnLogout();
        sessionStorage.clear();
        localStorage.clear();          
        this.toastMessageService.showInfoMessage('Logout Successful.');
      }
    },
      error => {  
        this.progressBar = false;       
        if (error.error !== undefined && error.error !== null) {
          this.toastMessageService.showErrorMessage(error.error.text);
        } else {
          this.toastMessageService.showErrorMessage(error.statusText);
        }
      }
    );
  }

  loggin(){
    this.routerService.RedirectLogin();
  }

}
